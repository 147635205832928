import api from '@/api/fyApi';
import bibleApi from '@/api/bibleApi';
import BaseModule from "@/common/BaseModule";
import bibleContents from '@/views/assets/contents';
import { Module, Store } from "vuex";
import { getVersionConfig } from './bible/search';
import device from './device';

export interface State {
    list: Array<MovieResource>,
    movie?: MovieAlbum,
    current?: MovieResource,
    bible: boolean,
    volume: number,
    chapter: number,
    lang: string,
    duration: number,
    currentTime: number,
    subtitle: { id: number, s: number, e: number }[],
}
const audios: any = {
    "和合本": "https://shengjing-ziyuan.sanmanuela.com/FM圣经朗读专用/ys磐石圣经和合本纯声/",
    "新译本": "https://shengjing-ziyuan.sanmanuela.com/FM圣经朗读专用/ys新译本朗读/",
    "niv": "https://shengjing-ziyuan.sanmanuela.com/FM圣经朗读专用/Read_Bible_NIV/",
    "儿童版": "https://shengjing-ziyuan.sanmanuela.com/ertong/",
    "戏剧版": "https://shengjing-ziyuan.sanmanuela.com/huayuyoushengxiju/"
}
function getAudioUrl(lang: string, i: number, j: number) {
    const book = bibleContents[i];
    let volume: ID = i + 1;
    if (volume < 10) {
        volume = '0' + volume;
    }
    let chapter: ID = j + 1;
    if (book.chapters > 100) {
        if (chapter < 10) {
            chapter = '00' + chapter;
        } else if (chapter < 100) {
            chapter = '0' + chapter;
        }
    } else {
        if (chapter < 10) {
            chapter = '0' + chapter;
        }
    }
    let path = volume + book.name;
    let langItem = getVersionConfig(lang)
    if (langItem.key == 'niv') {
        path = volume + '_' + book.path;
    }
    if (audios[langItem.label]) {
        return audios[langItem.label] + `${path}/${path}_${chapter}.mp3`;
    } else {
        return ''
    }
}

class BackMusic extends BaseModule<State>{
    constructor() {
        super('back-music')
    }

    generate(): Module<State, any> {
        return {
            namespaced: true,
            state: {
                list: [],
                bible: false,
                volume: 0,
                chapter: 0,
                lang: '',
                duration: 0,
                currentTime: 0,
                subtitle: [],
            },
            mutations: {
                updateList(state, { movie, list }) {
                    state.movie = movie;
                    state.list = list;
                },
                updateCurrent(state, item) {
                    state.current = item;
                },
                shutdown(state) {
                    state.list = []
                },

            },
            actions: {
                async play(store, item: MovieResource) {
                    store.state.bible = false
                    if (store.state.current && store.state.current.urlid == item.urlid) {
                        return
                    }
                    store.commit('updateCurrent', item);
                    try {
                        let data = await api.getMovieResourceUrl(item.movid, item.urlid, 2)
                        store.commit('updateCurrent', data);
                        api.postHit('vodmp3', item.urlid)
                    } catch (e) {
                        device.toast(store, '没有相关资源')
                    }
                },
                playBible({ state, commit }, { volume, chapter, lang }) {
                    state.bible = true
                    state.lang = lang
                    state.volume = volume
                    state.chapter = chapter

                    let langItem = getVersionConfig(lang)
                    let contentItem = bibleContents[volume]
                    let title = langItem.key == 'niv' ? `《${contentItem.english}》 Chapter ${chapter + 1}` : `《${contentItem.name}》第${chapter + 1}章`
                    let item = {
                        urlid: 0,
                        title,
                        movid: 0,
                        movie_title: '',
                        url: getAudioUrl(lang, volume, chapter),
                    }
                    commit('updateList', {
                        movie: {
                            thumb: '/logo.png',
                        }, list: [item]
                    })
                    commit('updateCurrent', item);
                },
                async loadAudoSubtitle({ state, commit }, version: string) {
                    try {
                        state.subtitle = await bibleApi.getAudioSubtitle(state.volume, state.chapter, version)
                    } catch (e) {
                        state.subtitle = []
                    }
                }
            }
        }
    }

    setPlayList(store: Store<any>, movie: MovieAlbum, list: MovieResource[]) {
        store.commit(this.key('updateList'), { movie, list })
    }

    play(store: Store<any>, item: MovieResource) {
        store.dispatch(this.key('play'), item);
    }

    shutdown(store: Store<any>) {
        store.commit(this.key('shutdown'))
    }

    playBible(store: Store<any>, volume: number, chapter: number, lang: string) {
        if (chapter < 0) {
            volume--
            if (volume >= 0) {
                chapter = bibleContents[volume].chapters - 1
            }
        } else if (chapter >= bibleContents[volume].chapters) {
            volume++
            if (volume < bibleContents.length) {
                chapter = 0
            }
        }
        store.dispatch(this.key('playBible'), { volume, chapter, lang })
    }

    loadAudoSubtitle(store: Store<any>, version: string) {
        store.dispatch(this.key('loadAudoSubtitle'), version)
    }

    getBibleOtherAudio(version: string, volume: number, chapter: number) {
        if (audios[version]) {
            return audios[version] + `${volume + 1}/${chapter + 1}.mp3`;
        }else{
            return ''
        }
    }
}

export default new BackMusic()