import { isSSR, getLang } from '../config';
import { HttpRequestOptions } from "@/common/http";
import BaseApi from "./BaseApi";
class ArticleApi extends BaseApi {
    constructor() {
        super(isSSR ? 'http://yuedu-api.sanmanuela.net' : '//yuedu-api.sanmanuela.net')
    }

    preRequest(options: HttpRequestOptions): HttpRequestOptions {
        options.query = Object.assign({}, options.query, { lang: getLang() })
        return options;
    }

    postRequest({ code, msg, data }: any) {
        if (code == 0) {
            return data
        }
        throw new Error(msg)
    }

    async getCategorys(): Promise<ArticleCategory[]> {
        let { data } = await this.get(`/v1/gzh/lists`)
        return data
    }

    getFeeds(page: number, pagesize: number, username: string): Promise<ArticleFeed[]> {
        return this.get('/v1/article/listByUser', { page, pagesize, username })
    }

    getDetail(id: string | number): Promise<Article> {
        return this.get('/v1/article/detail', { itemid: id, lazy: 1 })
    }

    postGood(itemid: number) {
        return this.get('/v1/article/zan', { itemid })
    }

    getComments(itemid: number | string) {
        return this.get(`/v2/comment/lists`, { itemid })
    }

    postComment(itemid: number | string, content: string) {
        return this.get(`/v2/comment/post`, { itemid, content })
    }
}

export default new ArticleApi()
