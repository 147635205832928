import { LOAD_MOVIE_CATEGORIES_PAGE } from '@/store/protocols'
export default [
    {
        path: '/content/category/id/:id',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_MOVIE_CATEGORIES_PAGE.join('/')
        }
    },
    {
        path: '/content/category/id/:id/index.html',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_MOVIE_CATEGORIES_PAGE.join('/')
        }
    },
    {
        path: '/content/category/id/:id/page/:page',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_MOVIE_CATEGORIES_PAGE.join('/')
        }
    },
    {
        path: '/content/category/id/:id/page/:page/index.html',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_MOVIE_CATEGORIES_PAGE.join('/')
        }
    },
    {
        path: '/content/category/id/:id/page/:page/order/:order?',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_MOVIE_CATEGORIES_PAGE.join('/')
        }
    },
    {
        path: '/index.php/content/category/id/:id/',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_MOVIE_CATEGORIES_PAGE.join('/')
        }
    },
    {
        path: '/index.php/content/category/id/:id/index.html',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_MOVIE_CATEGORIES_PAGE.join('/')
        }
    },
]