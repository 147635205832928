import detail from './detail'
import home from './home'
import Layout from './Root.vue'
import search from './search'
export default [
    {
        name: 'bibles',
        path: '/bibles',
        component: Layout,
        children: [
            ...home,
            ...detail,
            ...search,
        ]
    },
]