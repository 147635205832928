import { LOAD_ARTICLES_HOME } from '@/store/protocols'
export default [
    {
        path: '/articles',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_ARTICLES_HOME.join('/')
        }
    },
    {
        path: '/articles/index.html',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_ARTICLES_HOME.join('/')
        }
    },
    {
        path: '/articles/:id/index.html',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_ARTICLES_HOME.join('/')
        }
    }
]