import api, { MovieAlbumRequest } from '@/api/fyApi';
import BaseModule from "@/common/BaseModule";
import { Module, Store } from "vuex";
import { LOAD_MOVIE_ALL_PAGE, LOAD_SEO } from '../protocols';
export interface State {
    albums: MovieAlbum[],
    page: number,
    pageSize: number,
    total: number,
    childId: ID,
    order: string,
    style: string,
}

class MovieAll extends BaseModule<State>{
    constructor() {
        super(LOAD_MOVIE_ALL_PAGE[0])
    }
    generate(): Module<State, any> {
        return {
            namespaced: true,
            state: {
                albums: [],
                page: 0,
                pageSize: 24,
                total: 0,
                childId: 0,
                order: 'hot',
                style: 'album'
            },
            mutations: {
                updateChildId(state, id) {
                    state.childId = id
                },
                updateOrder(state, value) {
                    state.order = value
                },
                updateStyle(state, value) {
                    state.style = value
                },
                updateAlbums(state, result: PageResult<MovieAlbum>) {
                    state.albums = result.data;
                    state.page = result.current_page;
                    state.total = result.total;
                }
            },
            actions: {
                async loadData({ commit, state }, { params }: Route) {
                    commit('updateChildId', params.cid || '0')
                    commit('updateOrder', params.order || 'addtimeOrder')
                    commit('updateStyle', params.style || 'album')
                    let page = parseInt(params.page) || 0
                    let request: MovieAlbumRequest = {
                        category_id: state.childId,
                        offset: page * (state.pageSize || 24),
                        limit: 32
                    }
                    if (parseInt(params.cid) > 0) {
                        request.category_id = params.cid
                    }
                    if (params.order == 'addtimeOrder') {
                        request.addtimeOrder = 'DESC'
                    } else if (params.order == 'edittimeOrder') {
                        request.edittimeOrder = 'DESC'
                    } else if (params.order == 'recommend') {
                        request.recommend = 3
                    } else if (params.order == 'hitsOrder') {
                        request.hitsOrder = 'DESC'
                    }
                    let result = await api.getMovieAlbums(request)
                    result.current_page = page
                    commit('updateAlbums', result)
                },
                [LOAD_MOVIE_ALL_PAGE[1]]({ dispatch }, route: Route) {
                    return Promise.all([
                        dispatch('loadData', route),
                        dispatch(LOAD_SEO, { file: 'all', query: route.params }, { root: true })
                    ])
                }
            }
        }
    }

    updateStyle(store: Store<any>, value: string) {
        store.commit(this.key('updateStyle'), value)
    }
}

export default new MovieAll();