import { LOAD_BIBLE_HOME } from '@/store/protocols'
export default [
    {
        path: '/bibles',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_BIBLE_HOME.join('/')
        }
    },
    {
        path: '/bibles/index.html',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_BIBLE_HOME.join('/')
        }
    }
]