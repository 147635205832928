import api from '@/api/fyApi';
import BaseModule from "@/common/BaseModule";
import { Module } from "vuex";
import { LOAD_MOVIE_LATEST_PAGE, LOAD_SEO } from '../protocols';
export interface State {
    albums: MovieAlbum[],
}

class MovieLatest extends BaseModule<State>{
    constructor() {
        super(LOAD_MOVIE_LATEST_PAGE[0])
    }
    generate(): Module<State, any> {
        return {
            namespaced: true,
            state: {
                albums: [],
            },
            mutations: {
                updateAlbums(state, data) {
                    state.albums = data;
                }
            },
            actions: {
                async loadData({ commit }) {
                    let { data } = await api.getMovieAlbums({ edittimeOrder: 'DESC', page: 1, limit: 100 })
                    commit('updateAlbums', data)
                },
                [LOAD_MOVIE_LATEST_PAGE[1]]({ dispatch }) {
                    return Promise.all([
                        dispatch('loadData'),
                        dispatch(LOAD_SEO, { file: 'latest' }, { root: true })
                    ])
                }
            }
        }
    }
}

export default new MovieLatest();