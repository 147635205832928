import { LOAD_MOVIE_ALBUM_PAGE, LOAD_MOVIE_ALL_PAGE } from '@/store/protocols'
export default [
    {
        path: '/content/view/movid/:id',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_MOVIE_ALBUM_PAGE.join('/')
        }
    },
    {
        name: 'movies/albums',
        path: '/content/view/movid/:id/index.html',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_MOVIE_ALBUM_PAGE.join('/')
        }
    },
    {
        name: 'movies/all',
        path: '/content/index.html/:cid?/:page?/:order?/:style?',
        component: () => import('./All.vue'),
        meta: {
            asyncData: LOAD_MOVIE_ALL_PAGE.join('/')
        }
    }
]