import Http, { HttpRequestOptions } from '../common/http'

type Method = 'get' | 'post' | 'patch' | 'put' | 'delete'

export default class BaseApi {
    static token: string = ''
    static toast: Function
    private readonly _domain: string
    private readonly _http: Http

    constructor(domain: string) {
        this._domain = domain
        this._http = new Http()
    }

    preRequest(options: HttpRequestOptions): HttpRequestOptions {
        return options
    }

    postRequest(response: any, api: string) {
        return response
    }

    async request(method: Method, api: string, options: HttpRequestOptions, errorMessage?: string, silence: boolean = false) {
        if (!/^https?:\/\//.test(api)) {
            api = this._domain + api
        }
        options = this.preRequest(options)
        try {
            let response = await this._http.request(method, api, options)
            return this.postRequest(response, api)
        } catch (e) {
            console.error('request fail', api, e)
            // if (BaseApi.toast) {
            //     let url = this._http.url(api, options.query)
            //     BaseApi.toast('请求失败：' + url)
            // }
            throw e
        }
    }

    get(api: string, query?: Record<string, any>, errorMessage?: string) {
        return this.request('get', api, { query }, errorMessage)
    }

    post(api: string, body: any, query?: Record<string, any>, errorMessage?: string) {
        return this.request('post', api, { query, body }, errorMessage)
    }

    patch(api: string, body: any, query?: Record<string, any>, errorMessage?: string) {
        return this.request('patch', api, { query, body }, errorMessage)
    }

    put(api: string, body: any, query?: Record<string, any>, errorMessage?: string) {
        return this.request('put', api, { query, body }, errorMessage)
    }

    delete(api: string, query?: Record<string, any>, errorMessage?: string) {
        return this.request('delete', api, { query }, errorMessage)
    }
}