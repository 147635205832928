import api from '@/api/fyApi';
import userApi from '@/api/userApi'
import BaseModule from "@/common/BaseModule";
import { Module, Store } from "vuex";
import { LOAD_MOVIE_PLAYER_PAGE, LOAD_SEO, LOAD_MINE_FAVORITES } from '../protocols';

export interface State {
    album?: MovieAlbumDetail,
    movid: number,
    urlid: ID,
    resource?: MovieResource,
    isFavorite: boolean,
    played: boolean,
}

class MoviePlayer extends BaseModule<State> {
    constructor() {
        super(LOAD_MOVIE_PLAYER_PAGE[0])
    }

    generate(): Module<State, any> {
        return {
            namespaced: true,
            state: {
                movid: 0,
                urlid: 0,
                isFavorite: false,
                played: false,
            },
            mutations: {
                updateAlbum(state, data) {
                    state.album = data
                },
                updateUrlId(state, value) {
                    state.urlid = value
                },
                updateResource(state, data) {
                    state.resource = data
                }
            },
            actions: {
                async refreshFavorite({ state, rootState }) {
                    if (state.movid > 0) {
                        state.isFavorite = rootState[LOAD_MINE_FAVORITES[0]].list.some((item: any) => item.movid == state.movid)
                    }
                },
                async loadAlbum({ state, commit, rootState }, { params }: Route) {
                    let movid = parseInt(params.id)
                    if (!state.album || state.album.movid != movid) {
                        let result = await api.getMovieAlbum(movid);
                        commit('updateAlbum', result)
                    }
                    state.movid = movid
                    state.isFavorite = rootState[LOAD_MINE_FAVORITES[0]].list.some((item: any) => item.movid == movid)
                    let urlid = params.url as ID
                    if (!urlid && state.album && state.album.url_list.length > 0) {
                        urlid = state.album.url_list[0].urlid
                    }
                    commit('updateUrlId', urlid)
                },
                async loadVideoResource({ commit, state }) {
                    try {
                        let r = await api.getMovieResourceUrl(state.movid, state.urlid, 1)
                        commit('updateResource', r)
                        api.postHit('vodmp4', state.urlid)
                    } catch (e) {
                        state.resource = {
                            urlid: state.urlid,
                            title: '',
                            movid: state.movid,
                            movie_title: '',
                            url: '',
                            mp4: 0,
                            mp3: 0,
                            down_mp4: 0,
                            down_mp3: 0,
                            doc: 0,
                            ad: {
                                image_src: '',
                                image_url: '',
                            }
                        }
                    }
                },
                async [LOAD_MOVIE_PLAYER_PAGE[1]]({ dispatch, state }, route: Route) {
                    await dispatch('loadAlbum', route)
                    await dispatch('loadVideoResource', route)
                    await dispatch(LOAD_SEO, { file: 'player', query: Object.assign(route.params, { url: state.urlid }) }, { root: true })
                }
            }
        }
    }

    switchResource(store: Store<any>, urlid: ID) {
        store.commit(this.key('updateUrlId'), urlid)
        return store.dispatch(this.key('loadVideoResource'))
    }

    logPlayback(store: Store<any>, urlid: ID, duration: number, movid: ID) {
        userApi.addPlayback({ urlid, duration, movid })
    }
}

export default new MoviePlayer()