import axios, { Method } from 'axios'

export interface HttpRequestOptions {
    headers?: Record<string, string>,
    query?: Record<string, string | number | undefined>,
    body?: any,
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text',
}

export default class Http {
    private _getRequestHandles: Record<string, Array<{ resolve: Function, reject: Function }>> = {}
    url(url: string, query?: Record<string, string | number | undefined>) {
        if (query) {
            let args = Object.keys(query).filter(k => query[k] != undefined && query[k] != null).map(k => k + '=' + query[k]).join('&')
            if (args) {
                if (url.indexOf('?') == -1) {
                    url += '?' + args
                } else {
                    url += '&' + args
                }
            }
        }
        return url
    }

    private releaseGetHandles(key: string, err: any, data?: any) {
        let handles = this._getRequestHandles[key]
        delete this._getRequestHandles[key]
        for (let handle of handles) {
            try {
                if (err) {
                    handle.reject(err)
                } else {
                    handle.resolve(data)
                }
            } catch (e) {
                console.error(e)
            }
        }
    }

    request(method: Method, url: string, options: HttpRequestOptions): Promise<any> {
        if (method.toUpperCase() == 'GET') {
            const key = this.url(url, options.query)
            return new Promise((resolve, reject) => {
                if (this._getRequestHandles[key]) {
                    this._getRequestHandles[key].push({ resolve, reject })
                } else {
                    this._getRequestHandles[key] = [{ resolve, reject }]

                    this.request0(method, url, options).then(data => {
                        this.releaseGetHandles(key, false, data)
                    }).catch(err => {
                        this.releaseGetHandles(key, err)
                    })
                }
            })
        } else {
            return this.request0(method, url, options)
        }
    }

    private async request0(method: Method, url: string, options: HttpRequestOptions): Promise<any> {
        try {
            let { data } = await axios.request({
                method,
                url,
                headers: options.headers,
                params: options.query,
                data: options.body,
                responseType: options.responseType || 'json'
            })
            return data
        } catch (err: any) {
            console.error(err.message)
            let { response } = err;
            throw new Error(response.status + ':' + response.statusText)
        }
    }
}
