import { LOAD_MOVIE_HOT_PAGE } from '@/store/protocols'
export default [
    {
        path: '/content/hot',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_MOVIE_HOT_PAGE.join('/')
        }
    },
    {
        name: 'movies/hot',
        path: '/content/hot/index.html',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_MOVIE_HOT_PAGE.join('/')
        }
    }
]