import api from '@/api/fyApi';
import BaseModule from "@/common/BaseModule";
import { Module, Store } from "vuex";
import { LOAD_MOVIE_SEARCH_PAGE, LOAD_SEO } from '../protocols';
export interface State {
    albums: any[],
    page: number,
    pageSize: number,
    total: number,
    keywords: string,
    type: string,
    loading: boolean,
}

class MovieSearch extends BaseModule<State>{
    constructor() {
        super(LOAD_MOVIE_SEARCH_PAGE[0])
    }
    generate(): Module<State, any> {
        return {
            namespaced: true,
            state: {
                albums: [],
                page: 0,
                pageSize: 20,
                total: 0,
                keywords: '',
                type: '1',
                loading: false,
            },
            mutations: {
                updateKeywords(state) {
                    state.keywords = ''
                },
                updateNotFound(state, result) {
                    state.albums = result;
                    state.page = 0;
                    state.total = 0;
                }
            },
            actions: {
                async loadNotFound({ commit }) {
                    let notfound = await api.getMovieAlbums({ offset: 0, limit: 32, recommend: 1 })
                    commit('updateNotFound', notfound.data)
                },
                async loadData({ dispatch, state }, { params }: Route) {
                    if (params.kw) {
                        state.keywords = params.kw
                        state.page = parseInt(params.page) || 0
                        state.type = params.type || '1'
                        state.total = 0
                        state.albums = []
                        state.loading = true
                        try {
                            let result = await api.search(params.kw, state.type, state.page + 1, state.pageSize)
                            state.total = result.count
                            state.albums = result.list
                            if (result.count == 0) {
                                await dispatch('loadNotFound')
                            }
                        } catch (e) {
                            await dispatch('loadNotFound')
                        } finally {
                            state.loading = false
                        }
                    } else {
                        await dispatch('loadNotFound')
                    }
                },
                [LOAD_MOVIE_SEARCH_PAGE[1]]({ dispatch }, route: Route) {
                    return Promise.all([
                        dispatch('loadData', route),
                        dispatch(LOAD_SEO, { file: 'search', query: route.params }, { root: true })
                    ])
                }
            }
        }
    }

    clearSearch(store: Store<any>) {
        store.commit(this.key('updateKeywords'), '')
    }
}

export default new MovieSearch();