export function all(order?: string, cid: number = 0) {
    if (order) {
        return `/content/index.html/${cid}/0/${order}/album`
    } else {
        return '/content/index.html'
    }
}

export function category(cid: ID) {
    return `/content/category/id/${cid}/index.html`
}

export function album(id: ID) {
    return `/content/view/movid/${id}/index.html`
}

export function player(movid: ID, urlid?: ID, time?: number) {
    if (urlid) {
        let url = `/html/${movid}/${urlid}.html`
        if (time) {
            url += '?t=' + time
        }
        return url
    } else {
        return `/html/${movid}/.html`
    }
}

export function author(id?: ID) {
    let url = `/movies/authors`
    if (id) {
        url += '/' + id + '/index.html'
    } else {
        url += '/index.html'
    }
    return url
}

export function topic(id: ID) {
    return `/movies/topics/` + id + '/index.html'
}