import api from '@/api/fyApi';
import BaseModule from "@/common/BaseModule";
import { Module } from "vuex";
import { LOAD_HOME_PAGE, LOAD_SEO } from '../protocols';

export interface State {
    banners: HomeBanner[],
    notices: News[],
    recommends: MovieAlbum[][],
    updates: MovieAlbum[][],
    categorys: Array<{ id: ID, name: string, list: MovieAlbum[] }>,
    speakers: MovieSpeaker[],
}

class MovieHome extends BaseModule<State>{
    constructor() {
        super(LOAD_HOME_PAGE[0])
    }

    generate(): Module<State, any> {
        return {
            namespaced: true,
            state: {
                banners: [],
                notices: [],
                recommends: [],
                updates: [],
                categorys: [],
                speakers: [],
            },
            mutations: {
                updateRecommends(state, data) {
                    state.recommends = data
                },
                updateUpdates(state, data) {
                    state.updates = data
                },
                updateCategorys(state, data) {
                    state.categorys = data
                },
                updateSpeakers(state, data) {
                    state.speakers = data
                },
                updateBanners(state, data) {
                    state.banners = data
                },
                updateNotices(state, data) {
                    state.notices = data
                }
            },
            actions: {
                async loadData({ commit }) {
                    let { sliders, notices, recommends, updates, categorys, speakers } = await api.getHomeData()
                    commit('updateRecommends', recommends)
                    commit('updateUpdates', updates)
                    commit('updateCategorys', categorys)
                    commit('updateSpeakers', speakers)
                    commit('updateBanners', sliders)
                    commit('updateNotices', notices)
                },
                [LOAD_HOME_PAGE[1]]({ dispatch }) {
                    return Promise.all([
                        dispatch('loadData'),
                        dispatch(LOAD_SEO, { file: 'home' }, { root: true }),
                    ])
                }
            }
        }
    }
}

export default new MovieHome()