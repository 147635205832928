import home from './home'
import Layout from './Root.vue'
export default [
    {
        name: 'lives',
        path: '/lives',
        component: Layout,
        children: [
            ...home,
        ]
    },
]