import { Component, createSSRApp } from 'vue'
import { createRouter, createWebHistory, RouteLocationNormalized, Router, RouteRecordRaw } from 'vue-router'
import Application from './main'
import BaseApi from './api/BaseApi'
import './views/styles/base.scss'
import { setting, parseSearch, toSearch } from './config'

class ClientApplication extends Application {
    createVueApp(rootComponent: Component) {
        return createSSRApp(rootComponent)
    }

    createRouter(routes: RouteRecordRaw[]): Router {
        const router = createRouter({
            routes, history: createWebHistory(),
            scrollBehavior(to, from, savedPosition) {
                if (savedPosition) {
                    return savedPosition
                } else {
                    return { left: 0, top: 0 }
                }
            }
        })
        if (process.env.NODE_ENV == "development") {
            router.beforeResolve(this.beforeRouterResolve.bind(this))
        }
        return router
    }

    async render() {
        if (window.__ssr_init_state__) {
            this.store.replaceState(window.__ssr_init_state__)
        }
        let lang = localStorage.getItem('__lang')
        if (lang) {
            let li = this.store.state.i18.lang.list.indexOf(lang)
            if (this.store.state.i18.lang.now != li) {
                let query = parseSearch()
                if (query.lang) {
                    this.store.state.i18.lang.now = li
                } else {
                    query.lang = lang
                    let search = toSearch(query)
                    window.location.replace(location.origin + location.pathname + search + location.hash)
                    return
                }
            }
        }
        setting.lang = this.store.state.i18.lang.list[this.store.state.i18.lang.now]

        let session = window.localStorage.getItem("session");
        if (session) {
            this.store.dispatch('loadSession', session);
        }
        BaseApi.toast = (text: string) => {
            this.store.commit('device/updateToast', text)
        }
        this.store.subscribeAction(action => {
            if (action.type == 'saveSession') {
                if (action.payload) {
                    console.info('save session', action.payload)
                    window.localStorage.setItem('session', action.payload)
                } else {
                    console.info('clear session')
                    window.localStorage.removeItem('session')
                }
            }
        })
        this.store.dispatch('bible-search/init')
        await this.router.isReady()
        if (process.env.NODE_ENV != "development") {
            this.router.beforeResolve(this.beforeRouterResolve.bind(this))
        }
        this.app.mount('#app')
        setTimeout(() => {
            setting.first = false
        }, 1000);
    }

    async loadAsyncData(to: RouteLocationNormalized, actions: string[]) {
        try {
            await super.loadAsyncData(to, actions)
        } catch (e) {
            console.error(e)
        }
    }
}

new ClientApplication().render()