import api from '@/api/fyApi';
import BaseModule from "@/common/BaseModule";
import { Module } from "vuex";
import { LOAD_MOVIE_ALBUM_PAGE, LOAD_SEO, LOAD_MINE_FAVORITES } from "../protocols";

export interface State {
    detail?: MovieAlbumDetail,
    id: number,
    isFavorite: boolean,
    pageIndex: number,
    pageSize: number,
    pages: number[],
}

class MovieAlbums extends BaseModule<State> {
    constructor() {
        super(LOAD_MOVIE_ALBUM_PAGE[0])
    }

    generate(): Module<State, any> {
        return {
            namespaced: true,
            state: {
                id: 0,
                isFavorite: false,
                pageIndex: 0,
                pageSize: 20,
                pages: [],
            },
            mutations: {
                updateId(state, value) {
                    state.id = value
                    state.isFavorite = false
                },
                updateDetail(state, data) {
                    state.detail = data
                    let arr: number[] = []
                    let total = Math.ceil(data.url_list.length / state.pageSize)
                    for (let i = 0; i < total; i++) {
                        arr.push(i)
                    }
                    state.pages = arr
                    state.pageIndex = data.listsort == 1 ? arr.length - 1 : 0
                }
            },
            actions: {
                async refreshFavorite({ state, rootState }) {
                    if (state.id > 0) {
                        state.isFavorite = rootState[LOAD_MINE_FAVORITES[0]].list.some((item: any) => item.movid == state.id)
                    }
                },
                async loadDetail({ commit, rootState, state }, { params }: Route) {
                    let id = parseInt(params.id)
                    commit('updateId', id)
                    state.isFavorite = rootState[LOAD_MINE_FAVORITES[0]].list.some((item: any) => item.movid == id)
                    let result = await api.getMovieAlbum(id)
                    commit('updateDetail', result)
                    api.postHit('movie', id)
                },
                [LOAD_MOVIE_ALBUM_PAGE[1]]({ dispatch }, route: Route) {
                    return Promise.all([
                        dispatch('loadDetail', route),
                        dispatch(LOAD_SEO, { file: 'view', query: route.params }, { root: true })
                    ])
                }
            }
        }
    }
}

export default new MovieAlbums()