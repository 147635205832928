import fyApi from '@/api/fyApi';
import { Store, StoreOptions } from 'vuex';
import * as config from '../config';
import articlesHome from './articles/home';
import backMusic from './backMusic';
import bibleDetail from './bible/detail';
import bibleHome from './bible/home';
import bibleSearch from './bible/search';
import device from './device';
import liveHome from './lives/home';
import mineFavorites from './mine/favorites';
import mineNews from './mine/news';
import mineNotes from './mine/notes';
import minePlaybacks from './mine/playbacks';
import movieAlbum from './movies/album';
import movieAll from './movies/all';
import movieAuthor from './movies/author';
import movieAuthors from './movies/authors';
import movieCategories from './movies/categories';
import movieHome from './movies/home';
import movieHot from './movies/hot';
import movieLatest from './movies/latest';
import moviePlayer from './movies/player';
import moviePublish from './movies/publish';
import movieSearch from './movies/search';
import movieTopic from './movies/topic';
import * as ps from './protocols';
import user from './user';
import i18 from './i18'
export * from './protocols';

const description = "这是一个正规基督教网站，包含基督教信息、基督教视频、牧师讲道、福音电影、基督教动漫、基督教诗歌、等所有相关福音信息，本站是以提供正统基督教视频为主的网站，旨在为了更好的造就信徒服侍教会，促使教会和信徒在神的话语根基上更加稳固健康，抵制异端，正本清源。"
const keywords = "新网址ifuyin.tv,基督教网站,福音网站,基督教牧师讲道视频,福音视频,基督教影视,基督教视频,天国福音,基督教网站,基督教门户网,基督网站,耶稣视频-福音影视网"

export default function (): StoreOptions<ps.RootState> {
    return {
        state: {
            moviesConfig: {
                menus: [],
                categories: [],
                topics: [],
                links: [],
                footer_text: '',
                footer_logo: '',
                hotsearch: [],
                ucenter: false,
            },
            phoneAreas: [],
            dialogs: {
                count: 0,
                login: false,
                loginQr: false,
                comments: false,
                bibleSetting: false,
                imgCode: false,
            },
            seo: {
                title: '',
                keywords: '',
                description: '',
            },
            videoFullScreen: false,
            page404: false,
            darkMode: false,
        },
        mutations: {
            updateMoviesConfig(state, data: CommonConfig) {
                state.moviesConfig.menus = data.menus;
                state.moviesConfig.categories = data.categorys;
                state.moviesConfig.topics = data.specials;
                state.moviesConfig.links = data.links;
                state.moviesConfig.footer_text = data.footer_text;
                state.moviesConfig.footer_logo = data.footer_logo;
                state.moviesConfig.hotsearch = data.hotsearch.map(item => item.word)
                state.moviesConfig.ucenter = data.ucenter.open == 1
            },
            updateSeo(state, data: SeoData) {
                state.seo.title = data.title;
                state.seo.keywords = data.keywords || keywords;
                state.seo.description = data.description || description;
                if (!config.isSSR) {
                    document.title = data.title;
                }
            },
            updatePhoneAreas(state, data) {
                state.phoneAreas = data
            },
            updateDialog(state, { name, payload }) {
                console.info('----dialog', name, payload)
                //@ts-ignore
                state.dialogs[name] = payload
                if (payload) {
                    state.dialogs.count++
                } else {
                    state.dialogs.count--
                }
            },
            [ps.SET_404](state, value) {
                state.page404 = value;
            },
            switchDarkMode(state, darkMode) {
                state.darkMode = darkMode == 'true'
                localStorage.setItem('darkMode', darkMode)
            },
            loadDarkMode(state, mode) {
                let userMode = localStorage.getItem('darkMode')
                if (userMode) {
                    state.darkMode = userMode === 'true'
                } else {
                    state.darkMode = mode === 'true'
                }
            },
        },
        modules: {
            [i18.name]: i18.generate(),
            [movieHome.name]: movieHome.generate(),
            [movieCategories.name]: movieCategories.generate(),
            [movieAlbum.name]: movieAlbum.generate(),
            [movieAuthors.name]: movieAuthors.generate(),
            [movieAuthor.name]: movieAuthor.generate(),
            [movieHot.name]: movieHot.generate(),
            [moviePublish.name]: moviePublish.generate(),
            [movieLatest.name]: movieLatest.generate(),
            [movieAll.name]: movieAll.generate(),
            [movieSearch.name]: movieSearch.generate(),
            [movieTopic.name]: movieTopic.generate(),
            [moviePlayer.name]: moviePlayer.generate(),
            [liveHome.name]: liveHome.generate(),
            [articlesHome.name]: articlesHome.generate(),
            [bibleHome.name]: bibleHome.generate(),
            [bibleDetail.name]: bibleDetail.generate(),
            [bibleSearch.name]: bibleSearch.generate(),
            [backMusic.name]: backMusic.generate(),
            [device.name]: device.generate(),
            [user.name]: user.generate(),
            [mineNews.name]: mineNews.generate(),
            [mineFavorites.name]: mineFavorites.generate(),
            [minePlaybacks.name]: minePlaybacks.generate(),
            [mineNotes.name]: mineNotes.generate(),
        },
        actions: {
            loadSession({ dispatch }, session) {
                console.info('load session', session);
                dispatch(user.key('loadSession'), session);
            },
            saveSession() {
            },
            async loadPhoneAreas({ commit, state }) {
                if (state.phoneAreas.length == 0) {
                    let data = await fyApi.getPhoneAreas()
                    commit('updatePhoneAreas', data)
                }
            },
            async [ps.LOAD_COMMON]({ commit, state }) {
                console.info('load common.....')
                if (config.isSSR || state.moviesConfig.categories.length == 0) {
                    let data = await fyApi.getCommon()
                    commit('updateMoviesConfig', data)
                }
            },
            async [ps.LOAD_SEO]({ commit }, { file, query }) {
                try {
                    let data = await fyApi.getSeo(file, query)
                    commit('updateSeo', data)
                } catch (e) {
                }
            }
        }
    }
}

export function loadPhoneAreas(store: Store<any>) {
    store.dispatch('loadPhoneAreas')
}

export function showDialog(store: Store<any>, name: string, payload: any = true) {
    store.commit('updateDialog', { name, payload })
}

export function closeDialog(store: Store<any>, name: string) {
    store.commit('updateDialog', { name, payload: false })
}

export function showLogin(store: Store<any>) {
    return new Promise((resolve, reject) => {
        showDialog(store, 'login', { resolve, reject })
    })
}

export function showCommentWriter(store: Store<any>, id: number) {
    return new Promise((resolve, reject) => {
        showDialog(store, 'comments', { resolve, reject, id })
    })
}

export function showBibleVersionSetting(store: Store<any>) {
    return new Promise((resolve, reject) => {
        showDialog(store, 'bibleSetting', { resolve, reject })
    })
}


export function showImageCode(store: Store<any>, execute: Function) {
    return new Promise((resolve, reject) => {
        showDialog(store, 'imgCode', { resolve, reject, execute })
    })
}