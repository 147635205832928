export default [
    {
        "name": "创世记",
        "english": "Genesis",
        "path": "Genesis",
        "tag": "创",
        "chapters": 50,
    },
    {
        "name": "出埃及记",
        "english": "Exodus",
        "path": "Exodus",
        "tag": "出",
        "chapters": 40
    },
    {
        "name": "利未记",
        "english": "Leviticus",
        "path": "Leviticus",
        "tag": "利",
        "chapters": 27
    },
    {
        "name": "民数记",
        "english": "Numbers",
        "path": "Numbers",
        "tag": "民",
        "chapters": 36
    },
    {
        "name": "申命记",
        "english": "Deuteronomy",
        "path": "Deuteronomy",
        "tag": "申",
        "chapters": 34
    },
    {
        "name": "约书亚记",
        "english": "Joshua",
        "path": "Joshua",
        "tag": "书",
        "chapters": 24
    },
    {
        "name": "士师记",
        "english": "Judges",
        "path": "Judges",
        "tag": "士",
        "chapters": 21
    },
    {
        "name": "路得记",
        "english": "Ruth",
        "path": "Ruth",
        "tag": "得",
        "chapters": 4
    },
    {
        "name": "撒母耳记上",
        "english": "1 Samuel",
        "path": "1_Samuel",
        "tag": "撒上",
        "chapters": 31
    },
    {
        "name": "撒母耳记下",
        "english": "2 Samuel",
        "path": "2_Samuel",
        "tag": "撒下",
        "chapters": 24
    },
    {
        "name": "列王纪上",
        "english": "1 Kings",
        "path": "1_Kings",
        "tag": "王上",
        "chapters": 22
    },
    {
        "name": "列王纪下",
        "english": "2 Kings",
        "path": "2_Kings",
        "tag": "王下",
        "chapters": 25
    },
    {
        "name": "历代志上",
        "english": "1 Chronicles",
        "path": "1_Chronicles",
        "tag": "代上",
        "chapters": 29
    },
    {
        "name": "历代志下",
        "english": "2 Chronicles",
        "path": "2_Chronicles",
        "tag": "代下",
        "chapters": 36
    },
    {
        "name": "以斯拉记",
        "english": "Ezra",
        "path": "Ezra",
        "tag": "拉",
        "chapters": 10
    },
    {
        "name": "尼希米记",
        "english": "Nehemiah",
        "path": "Nehemiah",
        "tag": "尼",
        "chapters": 13
    },
    {
        "name": "以斯帖记",
        "english": "Esther",
        "path": "Esther",
        "tag": "斯",
        "chapters": 10
    },
    {
        "name": "约伯记",
        "english": "Job",
        "path": "Job",
        "tag": "伯",
        "chapters": 42
    },
    {
        "name": "诗篇",
        "english": "Psalm",
        "path": "Psalm",
        "tag": "诗",
        "chapters": 150
    },
    {
        "name": "箴言",
        "english": "Proverbs",
        "path": "Proverbs",
        "tag": "箴",
        "chapters": 31
    },
    {
        "name": "传道书",
        "english": "Ecclesiastes",
        "path": "Ecclesiastes",
        "tag": "传",
        "chapters": 12
    },
    {
        "name": "雅歌",
        "english": "Song of Solomon",
        "path": "Song_of_Solomon",
        "tag": "歌",
        "chapters": 8
    },
    {
        "name": "以赛亚书",
        "english": "Isaiah",
        "path": "Isaiah",
        "tag": "赛",
        "chapters": 66
    },
    {
        "name": "耶利米书",
        "english": "Jeremiah",
        "path": "Jeremiah",
        "tag": "耶",
        "chapters": 52
    },
    {
        "name": "耶利米哀歌",
        "english": "Lamentations",
        "path": "Lamentations",
        "tag": "哀",
        "chapters": 5
    },
    {
        "name": "以西结书",
        "english": "Ezekiel",
        "path": "Ezekiel",
        "tag": "结",
        "chapters": 48
    },
    {
        "name": "但以理书",
        "english": "Daniel",
        "path": "Daniel",
        "tag": "但",
        "chapters": 12
    },
    {
        "name": "何西阿书",
        "english": "Hosea",
        "path": "Hosea",
        "tag": "何",
        "chapters": 14
    },
    {
        "name": "约珥书",
        "english": "Joel",
        "path": "Joel",
        "tag": "珥",
        "chapters": 3
    },
    {
        "name": "阿摩司书",
        "english": "Amos",
        "path": "Amos",
        "tag": "摩",
        "chapters": 9
    },
    {
        "name": "俄巴底亚书",
        "english": "Obadiah",
        "path": "Obadiah",
        "tag": "俄",
        "chapters": 1
    },
    {
        "name": "约拿书",
        "english": "Jonah",
        "path": "Jonah",
        "tag": "拿",
        "chapters": 4
    },
    {
        "name": "弥迦书",
        "english": "Micah",
        "path": "Micah",
        "tag": "弥",
        "chapters": 7
    },
    {
        "name": "那鸿书",
        "english": "Nahum",
        "path": "Nahum",
        "tag": "鸿",
        "chapters": 3
    },
    {
        "name": "哈巴谷书",
        "english": "Habakkuk",
        "path": "Habakkuk",
        "tag": "哈",
        "chapters": 3
    },
    {
        "name": "西番雅书",
        "english": "Zephaniah",
        "path": "Zephaniah",
        "tag": "番",
        "chapters": 3
    },
    {
        "name": "哈该书",
        "english": "Haggai",
        "path": "Haggai",
        "tag": "该",
        "chapters": 2
    },
    {
        "name": "撒迦利亚书",
        "english": "Zechariah",
        "path": "Zechariah",
        "tag": "亚",
        "chapters": 14
    },
    {
        "name": "玛拉基书",
        "english": "Malachi",
        "path": "Malachi",
        "tag": "玛",
        "chapters": 4
    },
    {
        "name": "马太福音",
        "english": "Matthew",
        "path": "Matthew",
        "tag": "太",
        "chapters": 28
    },
    {
        "name": "马可福音",
        "english": "Mark",
        "path": "Mark",
        "tag": "可",
        "chapters": 16
    },
    {
        "name": "路加福音",
        "english": "Luke",
        "path": "Luke",
        "tag": "路",
        "chapters": 24
    },
    {
        "name": "约翰福音",
        "english": "John",
        "path": "John",
        "tag": "约",
        "chapters": 21
    },
    {
        "name": "使徒行传",
        "english": "Acts",
        "path": "Acts",
        "tag": "徒",
        "chapters": 28
    },
    {
        "name": "罗马书",
        "english": "Romans",
        "path": "Romans",
        "tag": "罗",
        "chapters": 16
    },
    {
        "name": "哥林多前书",
        "english": "1 Corinthians",
        "path": "1_Corinthians",
        "tag": "林前",
        "chapters": 16
    },
    {
        "name": "哥林多后书",
        "english": "2 Corinthians",
        "path": "2_Corinthians",
        "tag": "林后",
        "chapters": 13
    },
    {
        "name": "加拉太书",
        "english": "Galatians",
        "path": "Galatians",
        "tag": "加",
        "chapters": 6
    },
    {
        "name": "以弗所书",
        "english": "Ephesians",
        "path": "Ephesians",
        "tag": "弗",
        "chapters": 6
    },
    {
        "name": "腓立比书",
        "english": "Philippians",
        "path": "Philippians",
        "tag": "腓",
        "chapters": 4
    },
    {
        "name": "歌罗西书",
        "english": "Colossians",
        "path": "Colossians",
        "tag": "西",
        "chapters": 4
    },
    {
        "name": "帖撒罗尼迦前书",
        "english": "1 Thessalonians",
        "path": "1_Thessalonians",
        "tag": "帖前",
        "chapters": 5
    },
    {
        "name": "帖撒罗尼迦后书",
        "english": "2 Thessalonians",
        "path": "2_Thessalonians",
        "tag": "帖后",
        "chapters": 3
    },
    {
        "name": "提摩太前书",
        "english": "1 Timothy",
        "path": "1_Timothy",
        "tag": "提前",
        "chapters": 6
    },
    {
        "name": "提摩太后书",
        "english": "2 Timothy",
        "path": "2_Timothy",
        "tag": "提后",
        "chapters": 4
    },
    {
        "name": "提多书",
        "english": "Titus",
        "path": "Titus",
        "tag": "多",
        "chapters": 3
    },
    {
        "name": "腓利门书",
        "english": "Philemon",
        "path": "Philemon",
        "tag": "门",
        "chapters": 1
    },
    {
        "name": "希伯来书",
        "english": "Hebrews",
        "path": "Hebrews",
        "tag": "来",
        "chapters": 13
    },
    {
        "name": "雅各书",
        "english": "James",
        "path": "James",
        "tag": "雅",
        "chapters": 5
    },
    {
        "name": "彼得前书",
        "english": "1 Peter",
        "path": "1_Peter",
        "tag": "彼前",
        "chapters": 5
    },
    {
        "name": "彼得后书",
        "english": "2 Peter",
        "path": "2_Peter",
        "tag": "彼后",
        "chapters": 3
    },
    {
        "name": "约翰壹书",
        "english": "1 John",
        "path": "1_John",
        "tag": "约壹",
        "chapters": 5
    },
    {
        "name": "约翰贰书",
        "english": "2 John",
        "path": "2_John",
        "tag": "约贰",
        "chapters": 1
    },
    {
        "name": "约翰叁书",
        "english": "3 John",
        "path": "3_John",
        "tag": "约叁",
        "chapters": 1
    },
    {
        "name": "犹大书",
        "english": "Jude",
        "path": "Jude",
        "tag": "犹",
        "chapters": 1
    },
    {
        "name": "启示录",
        "english": "Revelation",
        "path": "Revelation",
        "tag": "启",
        "chapters": 22
    }
]