import BaseModule from "@/common/BaseModule";
import { ActionContext, Module, Store } from "vuex";

export interface State {
    toast: string
}

class Device extends BaseModule<State>{
    constructor() {
        super('device')
    }

    generate(): Module<State, any> {
        return {
            namespaced: true,
            state: {
                toast: ''
            },
            mutations: {
                updateToast(state, value) {
                    state.toast = value
                }
            }
        }
    }

    toast(store: Store<any> | ActionContext<any, any>, value: string) {
        store.commit(this.key('updateToast'), value, { root: true })
    }
}
export default new Device()