import articles from './articles';
import bibles from './bibles';
import lives from './lives';
import mine from './mine';
import movies from './movies';
export default [
    ...movies,
    ...lives,
    ...bibles,
    ...mine,
    ...articles,
    {
        name: 'bible-detail-inner',
        path: '/bibles/:lang/:volume/:chapter/:sid',
        component: () => import('./bibles/detail/Inner.vue'),
    }
]