import api from '@/api/userApi';
import BaseModule from "@/common/BaseModule";
import { Module, Store } from "vuex";
import { wrap401 } from '../user';

export interface State {
    list: NoteRecord[],
    page: number,
}

class NotesModule extends BaseModule<State>{
    constructor() {
        super('mine-notes')
    }

    generate(): Module<State, any> {
        return {
            namespaced: true,
            state: {
                list: [],
                page: 1,
            },
            mutations: {
                update(state, { list, page }) {
                    state.list = list;
                    state.page = page;
                }
            },
            actions: {
                loadList(store) {
                    return wrap401(store, async () => {
                        let { data, current_page } = await api.getNotes(1)
                        store.commit('update', { list: data, page: current_page })
                    })
                },
                remove(store, index: number) {
                    return wrap401(store, async () => {
                        let item = store.state.list[index]
                        await api.removeNote({ urlid: item.urlid, id: item.id })
                        store.state.list.splice(index, 1)
                    })

                }
            }
        }
    }

    loadList(store: Store<any>) {
        return store.dispatch(this.key('loadList'))
    }

    remove(store: Store<any>, index: number) {
        return store.dispatch(this.key('remove'), index)
    }
}

export default new NotesModule()