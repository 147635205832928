import { HttpRequestOptions } from "@/common/http";
import { getLang, isSSR } from '../config';
import BaseApi from "./BaseApi";

export interface MovieAlbumRequest {
    keyword?: string,
    category_id?: ID,
    speaker_id?: ID,
    recommend?: 1 | 2 | 3 | 4 | 5,
    page?: ID,
    offset?: number,
    limit: number,
    paginate?: number
    addtimeOrder?: 'DESC' | 'ASC',
    edittimeOrder?: 'DESC' | 'ASC',
    hitsOrder?: 'DESC' | 'ASC',
}

interface HomePageData {
    sliders: HomeBanner[],
    notices: News[],
    recommends: MovieAlbum[][],
    updates: MovieAlbum[][],
    categorys: Array<{ id: ID, name: string, list: MovieAlbum[] }>,
    speakers: MovieSpeaker[],
}

class FyApi extends BaseApi {
    token: string = ''
    ip: string = ''
    userAgent: string = ''
    constructor() {
        super(isSSR ? 'http://10.170.0.5:12345' : '/api')
    }

    preRequest(options: HttpRequestOptions): HttpRequestOptions {
        if (this.token) {
            options.headers = Object.assign({ token: this.token }, options.headers)
        }
        options.headers = Object.assign({ 'X-Forwarded-For': this.ip }, options.headers)
        options.query = Object.assign({}, options.query, { lang: getLang() })
        return options;
    }

    postRequest({ code, msg, data }: any) {
        if (code == 1) {
            return data
        }
        if (code == 401) {
            throw new Error('__401__')
        }
        throw new Error(msg)
    }

    async getCategories(parentid?: ID): Promise<{ current: MovieCategory, children: MovieCategory[], parent: MovieCategory[] }> {
        let { id, name, child, child_list, parent_list } = await this.get('/api/tv.category/index', { parentid, format: 1 })
        return {
            current: {
                id,
                name,
                parentid: parentid || 0,
                child,
                icon: '',
            },
            children: child_list,
            parent: parent_list,
        }
    }

    search(kw: string, type: string, page: number, limit: number): Promise<{ count: number, list: any[] }> {
        return this.get(`/api/tv.movie/search`, { kw, type, page, limit })
    }

    getMovieAlbums(query: MovieAlbumRequest): Promise<PageResult<MovieAlbum>> {
        query = Object.assign({ paginate: 1 }, query)
        return this.get(`/api/tv.movie/index`, query)
    }

    async getMovieAlbum(movid: ID): Promise<MovieAlbumDetail> {
        let data: MovieAlbumDetail = await this.get(`/api/tv.movie/detail`, { movid })
        for (let item of data.url_list) {
            item.movid = movid
        }
        return data
    }

    getMovieResourceUrl(movid: ID, urlid: ID, type: ID): Promise<MovieResource> {
        return this.get(`/api/tv.movie/url`, { movid, urlid, type })
    }

    getMovieAuthors(): Promise<{ speaker_list: MovieSpeaker[], movie_list: MovieAlbum[] }> {
        return this.get(`/api/tv.speaker/index`)
    }

    getMovieAuthor(id: ID): Promise<MovieSpeaker> {
        return this.get(`/api/tv.speaker/detail`, { id })
    }

    getMovieTopic(id: ID): Promise<MovieTopicDetail> {
        return this.get(`/api/tv.special/detail`, { id })
    }

    getMovieTopicAlbums(id: ID): Promise<PageResult<MovieAlbum>> {
        return this.get(`/api/tv.special/item`, { id })
    }

    getHomeData(): Promise<HomePageData> {
        return this.get(`/api/tv.index/index`)
    }

    getNews(page: number): Promise<PageResult<News>> {
        return this.get(`/api/tv.notice/index`, { page })
    }

    getCommon(): Promise<CommonConfig> {
        return this.get(`/api/tv.common/index`)
    }

    getSeo(file: string, query?: Record<string, string>): Promise<SeoData> {
        return this.get(`/api/tv.common/seo`, Object.assign({ file }, query))
    }

    getPhoneAreas(): Promise<PhoneArea[]> {
        return this.get(`/api/sms/area`)
    }

    getEmailSms(email: string, event: "register" | 'changeemail' | 'resetpwd') {
        return this.get(`/api/ems/send`, { email, event })
    }

    getPhoneSms(mobile: string, iddcode: string, event: "register" | "mobilelogin" | "changemobile" | 'resetpwd') {
        return this.get(`/api/sms/send`, { mobile, iddcode, event })
    }

    register(data: { type: 'mobile' | 'email', username: string, password: string, email?: string, mobile?: string, code: string, iddcode?: string }): Promise<{ userinfo: UserInfo }> {
        return this.post(`/api/user.register/index`, data);
    }

    resetPassword(data: { type: 'mobile' | 'email', email?: string, mobile?: string, iddcode?: string, newpassword: string, captcha: string }) {
        return this.post(`/api/user.profile/resetpwd`, data)
    }

    loginByPassword(data: { account: string, password: string }): Promise<{ userinfo: UserInfo }> {
        return this.post(`/api/user.login/index`, data)
    }

    loginByMobile(data: { mobile: string, iddcode: string, captcha: string }): Promise<{ userinfo: UserInfo }> {
        return this.post(`/api/user.login/mobile`, data)
    }

    getUserInfo(): Promise<UserInfo> {
        return this.post('/api/user.profile/index', {});
    }

    getAvatarList(): Promise<AvatarOption[]> {
        return this.get(`/api/common/avatar`)
    }

    updateUserInfo(data: { nickname?: string, avatar_id?: number }) {
        return this.post(`/api/user.profile/change`, data)
    }

    updateUserMobile(data: { mobile: string, iddcode: string, captcha: string }) {
        return this.post(`/api/user.profile/changemobile`, data)
    }

    updateUserEmail(data: { email: string, captcha: string }) {
        return this.post(`/api/user.profile/changeemail`, data)
    }

    updatePassword(data: { oldpassword: string, newpassword: string }) {
        return this.post(`/api/user.profile/changepwd`, data)
    }

    postSuggest(data: { content: string, contact: string }) {
        return this.post(`/api/tv.feedback/add`, data)
    }

    getFavorites(page: number): Promise<PageResult<Favorite>> {
        return this.post(`/api/user.tv.collect/index`, { page })
    }

    addFavorite(movid: ID) {
        return this.post('/api/user.tv.collect/add', { movid })
    }

    removeFavorite(data: { movid: ID, id?: ID }) {
        return this.post('/api/user.tv.collect/delete', Object.assign({ id: 0 }, data))
    }

    clearFavorites() {
        return this.post('/api/user.tv.collect/clear', {})
    }

    getPlaybacks(page: number): Promise<PageResult<Playback>> {
        return this.get('/api/user.tv.playrecord/index', { page })
    }

    addPlayback(data: { urlid: ID, duration: number }) {
        return this.post('/api/user.tv.playrecord/report', data)
    }

    removePlayback(urlid: ID) {
        return this.post('/api/user.tv.playrecord/delete', { urlid })
    }

    clearPlayback() {
        return this.post('/api/user.tv.playrecord/clear', {})
    }

    getNotes(page: number): Promise<PageResult<NoteRecord>> {
        return this.post('/api/user.tv.note/index', { page })
    }

    getNote(urlid: ID): Promise<{ content: string } | undefined> {
        return this.get(`/api/user.tv.note/edit`, { urlid })
    }

    addNote(data: { urlid: ID, content: string }) {
        return this.post('/api/user.tv.note/edit', data)
    }

    removeNote(data: { urlid: ID, id?: ID }) {
        return this.post('/api/user.tv.note/delete', Object.assign({ id: 0 }, data))
    }

    getLiveTypes() {
        return this.get(`/api/live.room/type`)
    }

    getLiveRooms(page: number, limit: number): Promise<{ list: LiveRoom[], count: number }> {
        return this.get(`/api/live.room/index`, { page, limit })
    }

    getLiveDetail(id: number | string): Promise<LiveRoom> {
        return this.get(`/api/live.room/detail`, { id })
    }

    postVote(id: number) {
        return this.get(`/api/live.room/vote`, { id })
    }

    postHit(ac: 'movie' | 'special' | 'speaker' | 'live' | 'vodmp4' | 'vodmp3', id: number | string) {
        return this.post(`/api/common.report/hit`, { ac, id })
    }
}


export default new FyApi()