import Application from "@/main"
import { RootState } from "@/store"

export default class ViewContext {
    private _application: Application
    private _stopMedia?: Function
    private _idSeed: number = 0
    constructor(application: Application) {
        this._application = application
    }
    get rootState() {
        return this._application.store.state as RootState
    }

    generateId() {
        return this._idSeed++;
    }

    go(params: Record<string, ID>, targetRouteName?: string) {
        const { name } = this._application.router.currentRoute.value
        //@ts-ignore
        this._application.router.push({ name: targetRouteName || name, params })
    }

    sleep(ms: number) {
        return new Promise(resolve => {
            setTimeout(resolve, ms);
        })
    }

    registerMedia(stopMediaCallback: Function) {
        if (this._stopMedia != stopMediaCallback) {
            console.info('register media stop another')
            if (this._stopMedia) {
                this._stopMedia()
            }
            this._stopMedia = stopMediaCallback
        }
    }

    createAliPlayer(domId: string, source: string, callback?: Function, options = { auto: true, poster: '', isLive: false, rate: true, isAudio: false }) {
        return new Aliplayer({
            id: domId,
            source,
            width: "100%",
            height: "100%",
            autoplay: options.auto,
            cover: options.poster,
            isLive: options.isLive,
            rePlay: false,
            playsinline: true,
            preload: true,
            controlBarVisibility: options.isAudio ? 'always' : "hover",
            useH5Prism: true,
            keyShortCuts: true,
            skinLayout: [
                {
                    "name": "bigPlayButton",
                    "align": "cc",
                },
                {
                    "name": "H5Loading",
                    "align": "cc"
                },
                {
                    "name": "errorDisplay",
                    "align": "tlabs",
                    "x": 0,
                    "y": 0
                },
                {
                    "name": "tooltip",
                    "align": "blabs",
                    "x": 0,
                    "y": 56
                },
                {
                    "name": "controlBar",
                    "align": "blabs",
                    "x": 0,
                    "y": 0,
                    "children": [
                        {
                            "name": "progress",
                            "align": "blabs",
                            "x": 0,
                            "y": 44
                        },
                        {
                            "name": "playButton",
                            "align": "tl",
                            "x": 15,
                            "y": 12
                        },
                        {
                            "name": "timeDisplay",
                            "align": "tl",
                            "x": 10,
                            "y": 7
                        },
                        {
                            "name": "fullScreenButton",
                            "align": "tr",
                            "x": 10,
                            "y": 12
                        },
                        {
                            "name": "volume",
                            "align": "tr",
                            "x": 5,
                            "y": 10
                        }
                    ]
                }
            ],
            components: options.rate ? [{
                name: 'RateComponent',
                type: AliPlayerComponent.RateComponent
            }] : []
        }, (player: any) => {
            console.log("The player is created");
            if (callback) {
                callback()
            }
            player.on('requestFullScreen', () => {
                if (window.innerWidth > 1000) {
                    this.rootState.videoFullScreen = true
                }

            })
            player.on('cancelFullScreen', () => {
                this.rootState.videoFullScreen = false
            })
        });
    }

    dispostAliPlayer(domId: string, player: any) {
        if (player && player.dispose) {
            player.dispose()
        }
        let dom = document.getElementById(domId)
        if (dom) {
            dom.innerHTML = ''
        }
    }
}