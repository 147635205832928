import BaseApi from "./BaseApi";
const textDomain = 'https://shengjing-ziyuan.sanmanuela.com'
const bibleDomain = "https://spirit.sanmanuela.com/api/bible"

class BibleApi extends BaseApi {
    constructor() {
        super('')
    }

    postRequest(data: any, api: string) {
        return data
    }

    getContent(volume: number, chapter: number, lang: string, template: string): Promise<string[]> {
        return this.get(textDomain + template.replace('${lang}', lang).replace('${v}', volume + '').replace('${c}', chapter + ''))
    }

    getAudioSubtitle(volume: number, chapter: number, version: string,) {
        if (version == 'hhb') {
            return this.get(textDomain + `/bible/subtitles/${volume}_${chapter}.json`);
        } else {
            return []
        }
    }

    search(query: { detail: string, lng: string, part?: string, volume?: string, page: number, size: number }) {
        return this.get(bibleDomain + '/bible2/search', query)
    }

    getContents() {
        return this.get(bibleDomain + '/bible2/contents')
    }
}

export default new BibleApi()
