import api from '@/api/fyApi';
import BaseModule from "@/common/BaseModule";
import { n2 } from '@/views/filters';
import { Module, Store } from "vuex";
import { LOAD_LIVE_HOME, SEO } from '../protocols';
export interface State {
    lives: LiveRoom[],
    live: LiveRoom | null,
    id: string | number,
}

class LiveHome extends BaseModule<State>{
    constructor() {
        super(LOAD_LIVE_HOME[0])
    }
    generate(): Module<State, any> {
        return {
            namespaced: true,
            state: {
                lives: [],
                live: null,
                id: '',
            },
            mutations: {
                update(state, data) {
                    state.live = Object.assign({}, state.live, data)
                }
            },
            actions: {
                async loadData({ state, dispatch }, route: Route) {
                    let { list } = await api.getLiveRooms(1, 20)
                    state.lives = list
                    if (list.length > 0) {
                        let id = route.params.id || list[0].id
                        if (id) {
                            return dispatch('loadDetail', id)
                        }
                    }
                },
                async loadDetail({ commit, state }, id) {
                    let room = await api.getLiveDetail(id)
                    state.id = id
                    if (room.item_json && room.item_json.length > 0) {
                        let dt = new Date()
                        let offset = dt.getTimezoneOffset() + 480

                        room.item_json.forEach(item => {
                            let ts = item.key.split(':')
                            let value = parseInt(ts[0]) * 60 + parseInt(ts[1])
                            let dayMinute = 24 * 60
                            value = value - offset
                            if (value < 0) {
                                value = value + dayMinute
                            } else if (value > dayMinute) {
                                value = value - dayMinute
                            }

                            let hours = Math.floor(value / 60)
                            let minutes = value % 60
                            item.time = hours * 100 + minutes
                            item.key = n2(hours) + ':' + n2(minutes)
                        })
                    }
                    commit('update', room)
                    const seoTitle = room.title + '-一起看-福音影视网|福音TV'
                    commit('updateSeo', Object.assign({}, SEO, { title: seoTitle }), { root: true })
                    api.postHit('live', id)
                },
                [LOAD_LIVE_HOME[1]]({ dispatch, commit }, route: Route) {
                    return Promise.all([
                        dispatch('loadData', route),
                        // dispatch(LOAD_SEO, { file: 'hot' }, { root: true })
                    ])
                }
            }
        }
    }

    reload(store: Store<any>, id: number) {
        store.dispatch(this.key('loadDetail'), id)
    }
    update(store: Store<any>, data: any) {
        store.commit(this.key('update'), data)
    }
}

export default new LiveHome();