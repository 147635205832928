import home from './home'
import Root from './Root.vue'
export default [
    {
        path: '/articles',
        component: Root,
        children: [
            ...home,
        ]
    },
]