import fyApi, { MovieAlbumRequest } from "@/api/fyApi";
import BaseModule from "@/common/BaseModule";
import { Module } from "vuex";
import { LOAD_MOVIE_CATEGORIES_PAGE, LOAD_SEO } from "../protocols";

export interface State {
    currentCategory?: MovieCategory,
    categories: MovieCategory[],
    parent: MovieCategory[],
    albums: MovieAlbum[],
    page: number,
    pageSize: number,
    total: number,
    cid: ID,
    order: string,
}

class MovieCategories extends BaseModule<State>{
    constructor() {
        super(LOAD_MOVIE_CATEGORIES_PAGE[0])
    }

    generate(): Module<State, any> {
        const self = this;
        return {
            namespaced: true,
            state: {
                categories: [],
                parent: [],
                albums: [],
                page: 0,
                pageSize: 24,
                total: 0,
                cid: '0',
                order: '',
            },
            mutations: {
                updateCurrent(state, current) {
                    state.currentCategory = current;
                },
                updateCid(state, id) {
                    state.cid = id
                },
                updateOrder(state, value) {
                    state.order = value
                },
                updateCategories(state, list) {
                    state.categories = list
                },
                updateParent(state, value) {
                    state.parent = value
                },
                updateAlbums(state, result: PageResult<MovieAlbum>) {
                    state.albums = result.data;
                    state.page = result.current_page;
                    state.total = result.total;
                }
            },
            actions: {
                async loadCategories({ commit }, { params }: Route) {
                    let { current, children, parent } = await fyApi.getCategories(params.id)
                    commit('updateCid', params.id)
                    commit('updateCurrent', current)
                    commit('updateCategories', children)
                    commit('updateParent', parent)
                },
                async loadMovieAlbums({ commit, state }, { params }: Route) {
                    commit('updateOrder', params.order || '')
                    let page = parseInt(params.page) || 0;
                    let request: MovieAlbumRequest = {
                        category_id: params.id,
                        offset: page * (state.pageSize || 24),
                        limit: 32
                    }
                    if (params.order == 'hot') {
                        request.hitsOrder = 'DESC'
                    } else if (params.order == 'time') {
                        request.edittimeOrder = 'DESC'
                    }
                    let result = await fyApi.getMovieAlbums(request)
                    result.current_page = page
                    commit('updateAlbums', result)
                },
                [LOAD_MOVIE_CATEGORIES_PAGE[1]]({ dispatch }, route: Route) {
                    return Promise.all([
                        dispatch('loadCategories', route),
                        dispatch('loadMovieAlbums', route),
                        dispatch(LOAD_SEO, { file: 'category', query: route.params }, { root: true }),
                    ])
                }
            }
        }
    }
}

export default new MovieCategories()