import { HttpRequestOptions } from "@/common/http";
import { getLang } from '../config';
import BaseApi from "./BaseApi";
const appid = ''
const device = 'pc'
class UserApi extends BaseApi {
    token: string = ''
    constructor() {
        super('https://users-api.sanmanuela.net')
    }

    preRequest(options: HttpRequestOptions): HttpRequestOptions {
        if (this.token) {
            options.query = Object.assign({ token: this.token }, options.query, { lang: getLang() })
        }
        // options.headers = Object.assign({ 'X-Forwarded-For': this.ip }, options.headers)
        return options;
    }
    postRequest(data: any, api: string) {
        const noWraps = ['/push/sub', '/api/captcha/image64']
        if (noWraps.some(s => api.indexOf(s) >= 0)) {
            return data
        }
        if (api.indexOf('/api/feedback/submit') >= 0 || api.indexOf('/api/feedback/movieError') >= 0) {
            if (data.code == 1) {
                return data.data
            }
        }
        if (api.indexOf('/api/favorite/add') >= 0) {
            if (data.msg == '您已经收藏过该专辑') {
                return {}
            }
        }
        if (data.code == 0) {
            return data.data
        }
        throw new Error(data.msg)
    }

    getLoginQrcode(): Promise<any> {
        return this.get(`/api/login/getticket`)
    }

    getEmailSms(email: string, event: "register" | 'login' | 'forgot_email') {
        return this.post(`/api/ems/send`, { email, event, appid, device })
    }

    getPhoneSms(mobile: string, itc: string, captcha_key: string, captcha_val: string) {
        return this.get(`/api/sms/send`, { mobile, itc, appid, captcha_key, captcha_val })
    }

    getImageCode() {
        return this.get('/api/captcha/image64', { appid, t: Date.now() })
    }

    register(data: { type: 'mobile' | 'email', username: string, password: string, email?: string, mobile?: string, code: string, iddcode?: string }): Promise<any> {
        if (data.type == 'email') {
            return this.post(`/api/register/email`, { email: data.email, code: data.code, password: data.password, password_confirm: data.password, appid, device });
        } else {
            return this.post(`/api/auth/login_mobile`, { mobile: data.mobile, itc: data.iddcode, vcode: data.code, appid });
        }
    }

    resetPassword(data: { email?: string, mobile?: string, iddcode?: string, newpassword: string, captcha: string }) {
        return this.post(`/api/forgot/email`, { email: data.email, code: data.captcha, password: data.newpassword, password_confirm: data.newpassword, appid, device })
    }

    loginByPassword(data: { account: string, password: string }): Promise<any> {
        return this.post(`/api/login/email`, { type: 1, email: data.account, password: data.password, appid, device })
    }

    loginByMobile(data: { mobile: string, iddcode: string, captcha: string }): Promise<any> {
        return this.post(`/api/auth/login_mobile`, { mobile: data.mobile, itc: data.iddcode, vcode: data.captcha, appid });
    }

    getUserInfo() {
        return this.get('/api/member/account')
    }

    async getFavorites(page: number, pagesize: number): Promise<PageResult<Favorite>> {
        let { data, total, current_page } = await this.get(`/api/favorite/index`, { page, pagesize })
        for (let item of data) {
            item.create_time = item.addtime
            item.movie = {
                movid: item.movid,
                title: item.title,
                speaker: '',
                thumb: item.thumb,
                addtime: item.addtime,
                edittime: item.addtime,
            }
        }
        return { total, data, current_page }
    }

    addFavorite(movid: ID) {
        return this.get('/api/favorite/add', { mid: 1, tid: movid })
    }

    removeFavorite(data: { movid: ID, id?: ID }) {
        return this.get('/api/favorite/delete', { id: data.id, tid: data.movid })
    }

    clearFavorites() {
        // return this.get('/api/user.tv.collect/clear', {})
    }

    async getPlaybacks(page: number): Promise<PageResult<Playback>> {
        let { data, total, current_page } = await this.get('/api/history/index', { mid: 1 })
        for (let item of data) {
            item.create_time = item.addtime
            item.update_time = item.addtime
            item.movid = item.tid
            item.urlid = item.urlid
            item.duration = item.paytime
            item.type = item.paytype
            item.url = {
                title: item.sort_title
            }
            item.movie = {
                movid: item.movid,
                title: item.title,
                speaker: item.director,
                thumb: item.thumb,
                addtime: item.addtime,
                edittime: item.addtime,
            }
        }
        return { data, total, current_page }
    }

    addPlayback(data: { urlid: ID, duration: number, movid: ID }) {
        return this.get('/api/history/add', { mid: 1, tid: data.movid, urlid: data.urlid, paytime: Math.floor(data.duration), paytype: 1 })
    }

    removePlayback(id: ID) {
        return this.get('/api/history/delete', { id })
    }

    clearPlayback() {
        return this.get('/api/history/clean', { mid: 1 })
    }

    async getNotes(page: number): Promise<PageResult<NoteRecord>> {
        let { data, total, current_page } = await this.get('/api/notepad/index', { page, mid: 1 })
        for (let item of data) {
            item.movid = item.tid
            item.movie = {
                title: item.title,
                thumb: '',
                speaker: ''
            }
            item.url = {
                title: item.subtitle,
                seo_title: item.subtitle
            }
        }
        return { data, total, current_page }
    }

    getNote(urlid: ID, movid: ID): Promise<{ content: string, id: number } | undefined> {
        return this.get(`/api/notepad/detail`, { urlid, tid: movid, mid: 1 })
    }

    addNote(data: { urlid: ID, movid: ID, content: string, title: string, subtitle: string, }) {
        return this.post('/api/notepad/add', { mid: 1, tid: data.movid, urlid: data.urlid, title: data.title, subtitle: data.subtitle, content: data.content })
    }

    saveNote(data: { id: ID, title: string, subtitle: string, content: string }) {
        return this.post('/api/notepad/edit', data)
    }

    removeNote(data: { urlid: ID, id?: ID }) {
        return this.get('/api/notepad/delete', Object.assign({ id: 0 }, data))
    }

    postSuggest(data: { content: string, contact: string, movid?: ID }) {
        if (data.movid) {
            return this.post(`https://data-api.sanmanuela.net/api/feedback/movieError`, data)
        } else {
            return this.post(`https://data-api.sanmanuela.net/api/feedback/submit`, data)
        }
    }
}

export default new UserApi()
