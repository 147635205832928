import api from '@/api/fyApi';
import userApi from '@/api/userApi';
import BaseModule from "@/common/BaseModule";
import { ActionContext, Module, Store } from "vuex";
import device from './device';
import { LOAD_MINE_FAVORITES } from './protocols'

export interface State {
    info?: UserInfo
}

const __401__ = '__401__'

export async function wrap401(store: Store<any> | ActionContext<any, any>, handler: Function) {
    try {
        return await handler()
    } catch (e: any) {
        if (e.message == __401__ || e.message == '401:Unauthorized') {
            device.toast(store, '当前用户状态已过期，请重新登录')
            new Promise((resolve, reject) => {
                store.commit('updateDialog', { name: 'login', payload: { resolve, reject } }, { root: true })
            })
        }
        throw e
    }
}

class User extends BaseModule<State> {
    constructor() {
        super("user")
    }

    generate(): Module<State, any> {
        return {
            namespaced: true,
            state: {
            },
            mutations: {
                update(state, data) {
                    state.info = data
                },
                updateAvatar(state, avatar) {
                    if (state.info) {
                        state.info.avatar = avatar
                    }
                },
                updateNickname(state, nickname) {
                    if (state.info) {
                        state.info.nickname = nickname
                    }
                },
                updateEmail(state, email) {
                    if (state.info) {
                        state.info.email = email
                    }
                },
                updateMobile(state, mobile) {
                    if (state.info) {
                        state.info.mobile = mobile
                    }
                }
            },
            actions: {
                async loadSession(context, session) {
                    api.token = session
                    userApi.token = session
                    try {
                        let info = await userApi.getUserInfo()
                        context.commit('update', {
                            "id": info.userid,
                            "username": info.username,
                            "nickname": info.nickname,
                            "mobile": '',
                            "email": info.email,
                            "avatar": info.headimgurl,
                            "token": session,
                        })
                        context.dispatch('saveSession', session, { root: true })
                        context.dispatch('mine-favorites/load-mine-favorites-page', {}, { root: true })
                    } catch (e: any) {
                        if (e.message == __401__ || e.message == '401:Unauthorized') {
                            api.token = ''
                            context.dispatch('saveSession', '', { root: true })
                        }
                    }
                },
                updateAvatar(context, avatar: AvatarOption) {
                    return wrap401(context, async () => {
                        await api.updateUserInfo({ avatar_id: avatar.id })
                        context.commit('updateAvatar', avatar.avatar_url)
                    })
                },
                updateNickname(context, nickname: string) {
                    return wrap401(context, async () => {
                        await api.updateUserInfo({ nickname })
                        context.commit('updateNickname', nickname)
                    })
                },
                updatePassword(context,) {

                },
                updateEmail(context, { email, captcha }) {
                    return wrap401(context, async () => {
                        await api.updateUserEmail({ email, captcha })
                        context.commit('updateEmail', email)
                    })
                },
                updateMobile(context, { mobile, captcha, iddcode }) {
                    return wrap401(context, async () => {
                        await api.updateUserMobile({ mobile, iddcode, captcha })
                        context.commit('updateMobile', mobile)
                    })
                },
            }
        }
    }

    online(store: Store<any>, showLogin: boolean = true) {
        if ((store.state[this.name] as State).info) {
            return true
        }
        if (showLogin) {
            new Promise((resolve, reject) => {
                store.commit('updateDialog', { name: 'login', payload: { resolve, reject } }, { root: true })
            })
        }
        return false
    }

    login(store: Store<any>, info: UserInfo) {
        store.dispatch('loadSession', info.token)
    }

    logout(store: Store<any>) {
        api.token = ''
        userApi.token = ''
        store.dispatch('saveSession', '')
        store.commit(this.key('update'), undefined)
        store.commit(LOAD_MINE_FAVORITES[0] + '/clear', '')
    }

    updateAvatar(store: Store<any>, avatar: AvatarOption) {
        return store.dispatch(this.key('updateAvatar'), avatar)
    }

    updateNickname(store: Store<any>, nickname: string) {
        return store.dispatch(this.key('updateNickname'), nickname)
    }

    updatePassword(store: Store<any>, oldpassword: string, newpassword: string) {
        return wrap401(store, async () => {
            await api.updatePassword({ oldpassword, newpassword })
        })
    }

    updateEmail(store: Store<any>, email: string, captcha: string) {
        return store.dispatch(this.key('updateEmail'), { email, captcha })
    }

    updateMobile(store: Store<any>, mobile: string, iddcode: string, captcha: string) {
        return store.dispatch(this.key('updateMobile'), { mobile, iddcode, captcha })
    }
}

export default new User()