import api, { MovieAlbumRequest } from '@/api/fyApi';
import BaseModule from "@/common/BaseModule";
import { Module } from "vuex";
import { LOAD_MOVIE_AUTHOR_PAGE, LOAD_SEO } from '../protocols';
export interface State {
    speaker?: MovieSpeaker,
    albums: MovieAlbum[],
    page: number,
    pageSize: number,
    total: number,
}

class MovieAuthor extends BaseModule<State> {
    constructor() {
        super(LOAD_MOVIE_AUTHOR_PAGE[0])
    }
    generate(): Module<State, any> {
        return {
            namespaced: true,
            state: {
                albums: [],
                page: 0,
                pageSize: 24,
                total: 0,
            },
            mutations: {
                updateSpeaker(state, data) {
                    state.speaker = data;
                },
                updateAlbums(state, result: PageResult<MovieAlbum>) {
                    state.albums = result.data;
                    state.page = result.current_page;
                    state.total = result.total;
                }
            },
            actions: {
                async loadAuthor({ commit }, { params }: Route) {
                    let speaker = await api.getMovieAuthor(params.id)
                    commit('updateSpeaker', speaker)
                    api.postHit('speaker', params.id)
                },
                async loadAlbums({ commit, state }, { params }: Route) {
                    let page = parseInt(params.page) || 0
                    let request: MovieAlbumRequest = {
                        speaker_id: params.id,
                        offset: page * (state.pageSize || 24),
                        limit: 32
                    }
                    let result = await api.getMovieAlbums(request)
                    result.current_page = page;
                    commit('updateAlbums', result)
                },
                [LOAD_MOVIE_AUTHOR_PAGE[1]]({ dispatch }, route: Route) {
                    return Promise.all([
                        dispatch('loadAuthor', route),
                        dispatch('loadAlbums', route),
                        dispatch(LOAD_SEO, { file: 'speaker', query: route.params }, { root: true })
                    ])
                }
            }
        }
    }
}

export default new MovieAuthor();