import api from '@/api/userApi';
import BaseModule from "@/common/BaseModule";
import { Module, Store } from "vuex";
import { wrap401 } from '../user';

export interface State {
    list: Playback[],
    page: number,
}

class PlaybackModule extends BaseModule<State>{
    constructor() {
        super('mine-playbacks')
    }

    generate(): Module<State, any> {
        return {
            namespaced: true,
            state: {
                list: [],
                page: 1,
            },
            mutations: {
                update(state, { list, page }) {
                    state.list = list
                    state.page = page
                }
            },
            actions: {
                loadList(store) {
                    return wrap401(store, async () => {
                        let { data, current_page } = await api.getPlaybacks(1)
                        store.commit('update', { list: data, page: current_page })
                    })
                },
                remove(store, index: number) {
                    return wrap401(store, async () => {
                        let item = store.state.list[index]
                        await api.removePlayback(item.id)
                        // await api.removePlayback(item.urlid)
                        store.state.list.splice(index, 1)
                    })
                },
                clear(store) {
                    return wrap401(store, async () => {
                        await api.clearPlayback()
                        store.commit('update', { list: [], page: 1 })
                    })
                }
            }
        }
    }

    loadList(store: Store<any>) {
        store.dispatch(this.key('loadList'))
    }

    remove(store: Store<any>, index: number) {
        store.dispatch(this.key('remove'), index)
    }

    clear(store: Store<any>) {
        store.dispatch(this.key('clear'))
    }
}

export default new PlaybackModule()