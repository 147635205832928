import P404 from '@/views/components/P404.vue'
import albums from './albums'
import authors from './authors'
import categories from "./categories"
import home from './home'
import hot from './hot'
import latest from './latest'
import player from './player'
import Root from './Root.vue'
import search from './search'
import topics from './topics'
export default [
    {
        name: 'movies',
        path: '/movies',
        component: Root,
        children: [
            ...home,
            ...albums,
            ...authors,
            ...categories,
            ...hot,
            ...latest,
            search,
            ...topics,
            player,
            {
                path: '/404',
                component: P404,
            },
        ]
    },
]