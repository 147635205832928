import { LOAD_LIVE_HOME } from '@/store/protocols'

export default [
    {
        path: '/lives',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_LIVE_HOME.join('/')
        }
    },
    {
        path: '/lives/index.html',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_LIVE_HOME.join('/')
        }
    },
    {
        path: '/lives/:id/index.html',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_LIVE_HOME.join('/')
        }
    }
]