import BaseModule from "@/common/BaseModule";
import { Module } from "vuex";

interface TopMenu {
    movie: string,
    live: string,
    bible: string,
    article: string,
    dark: string,
    light: string,
}

interface MovieMenu {
    home: string,
    hot: string,
    new_add: string,
    new_update: string,
    sunday: string,
    all: string,
    category: string,
    special_topic: string,
}

interface MovieTop {
    search: string,
    search_placeholder: string,
    search_history: string,
    search_clear: string,
    search_words: string,
    login: string,
    my_play: string,
    my_favorite: string,
    my_note: string,
    notices: string,
    about: string,
    suggest: string,
    quit: string,
    play_records: string,
    all_records: string,
}

interface MovieText {
    notices: string,
    more: string,
    new_add: string,
    new_update: string,
    hot_recommend: string,
    hot_rank: string,
    author: string,
    software: string,
    software_intro: string,
    software_join: string,
    software_android: string,
    software_ios: string,
    hot_intro: string,
    add_intro: string,
    update_intro: string,
    empty: string,
    filter_view: string,
    filter_view_card: string,
    filter_view_text: string,
    filter_sort: string,
    filter_sort_default: string,
    filter_sort_add: string,
    filter_sort_update: string,
    filter_sort_hot: string,
    filter_sort_recommend: string,
    filter_category: string,
    recommend_intro: string,
    recommend_videos: string,
    album_category: string,
    album_author: string,
    album_intro: string,
    album_more: string,
    album_play: string,
    album_favorite: string,
    album_playlist: string,
    album_sort_asc: string,
    album_sort_desc: string,
    album_list_video: string,
    album_list_audio: string,
    album_list_video_down: string,
    album_list_audio_down: string,
    album_list_doc_down: string,
    album_recommend: string,
    album_hot: string,
    album_software: string,
    album_software_intro: string,
    album_software_download: string,
    album_notfound: string,
    player_current: string,
    player_list: string,
    player_favorite: string,
    player_note: string,
    player_suggest: string,
    player_download: string,
    player_phone: string,
    player_play_list: string,
    player_play_one_loop: string,
    player_recommend: string,
    player_intro: string,
    player_category: string,
    player_source: string,
    player_language: string,
    player_author: string,
    author_label: string,
    author_recommend: string,
    author_intro: string,
    author_videos: string,
    search: string,
    search_album: string,
    search_video: string,
    search_author: string,
    search_loading: string,
    search_notfound: string,
    search_recommend: string,
    search_more: string,
    search_album_author: string,
    search_album_intro: string,
    search_album_found: string,
    search_album_detail: string,
    search_album_play: string,
    search_list_no: string,
    search_list_title: string,
    search_list_album: string,
    search_list_author: string,
    search_author_detail: string,
}

interface LiveText {
    channel: string,
    live_wait: string,
    live_play: string,
    live_end: string,
    live_replay: string,
    live_start: string,
    live_viewCount: string,
    online: string,
    download: string,
    good: string,
    sponsor: string,
    time: string,
    content: string,
    empty: string,
    liveIsStart: string,
    liveIsEnd: string,
    clickToReplay: string,
}

interface ArticleText {
    channel: string,
    today: string,
    latest7: string,
    download: string,
    read: string,
    good: string,
    comments: string,
    addComment: string,
    guest: string,
    top: string,
    noComments: string,
    notfound: string,
    comment_placeholder: string,
    comment_error: string,
    comment_success: string,
    comment_submit: string,
}

interface BibleText {
    bible: string,
    new: string,
    old: string,
    home_title: string,
    home_continue: string,
    home_audio: string,
    home_audio_intro: string,
    home_audio_do: string,
    home_text: string,
    home_text_intro: string,
    home_text_do: string,
    home_app: string,
    home_app_intro: string,
    home_app_do: string,
    home_bible: string,
    home_bible_from: string,
    app: string,
    app_download: string,
    chapter: string,
    prev: string,
    next: string,
    playAudio: string,
    book: string,
    book_notfound: string,
    fontsize: string,
    theme: string,
    version_placeholder: string,
    version_multi: string,
    search: string,
    search_placeholder: string,
    search_empty: string,
    search_result: string,
    search_result_text: string,
    search_target: {
        '': string,
        new: string,
        old: string,
        moxi: string,
        history: string,
        poem: string,
        big: string,
        small: string,
        four: string,
        church: string,
        paul: string,
        other: string,
        revelation: string,
    }
}

interface MineText {
    menu: {
        my_play: string,
        my_favorite: string,
        my_note: string,
        notices: string,
        about: string,
        suggest: string,
    },
    contentPlaceHolder: string,
    contentError: string,
    contactPlaceHolder: string,
    contactError: string,
    submit: string,
    successSubmit: string,
    errorSubmit: string,
    notePlaceholder: string,
    noteSearchPlaceholder: string,
    noteError: string,
    play: string,
    save: string,
    clear: string,
    delete: string,
    empty: string,
    successSave: string,
    successDelete: string,
    errorSave: string,
    errorDelete: string,
    errorClear: string,
}

interface CommonText {
    first_page: string,
    prev_page: string,
    next_page: string,
    last_page: string,
    p404: string,
    audio_not_found: string,
    audio_playlist: string,
    audio_versions: string,
}

interface LoginText {
    back: string,
    register: string,
    forget: string,
    phone_placeholder: string,
    email_placeholder: string,
    password_placeholder: string,
    smscode_placeholder: string,
    smscode_send: string,
    do_login: string,
    do_register: string,
    do_reset_pwd: string,
    login_qrcode: string,
    login_email: string,
    login_sms: string,
    qrcode_tip: string,
    agree: string,
    agree_user: string,
    agree_private: string,
    account_error: string,
    email_error: string,
    phone_error: string,
    login_error: string,
    register_error: string,
    register_success: string,
    reset_error: string,
    reset_success: string,
    imgcode_tip: string,
    imgcode_placeholder: string,
    imgcode_reset: string,
    imgcode_submit: string,
}


interface LangText {
    topMenu: TopMenu,
    movieMenu: MovieMenu,
    movieTop: MovieTop,
    movieText: MovieText,
    mineText: MineText,
    live: LiveText,
    article: ArticleText,
    bible: BibleText,
    login: LoginText,
    common: CommonText
}

export interface State {
    lang: {
        now: number, name: string[], list: string[]
    },
    texts: Record<string, LangText>
}


class I18 extends BaseModule<State> {
    constructor() {
        super('i18')
    }

    generate(): Module<State, any> {
        return {
            namespaced: true,
            state: {
                lang: {
                    now: 0,
                    name: ['简体中文', 'English'],
                    list: ['zh-CN', 'en-US']
                },
                texts: {
                    'zh-CN': {
                        topMenu: {
                            movie: '影视',
                            live: '一起看',
                            bible: '圣经',
                            article: '阅读',
                            dark: '深色模式',
                            light: '浅色模式'
                        },
                        movieMenu: {
                            home: '首页',
                            hot: '热播排行',
                            new_add: '最新发布',
                            new_update: '最近更新',
                            sunday: '主日讲道',
                            all: '全部视频',
                            category: '分类',
                            special_topic: '专题推荐'
                        },
                        movieTop: {
                            search: '搜素',
                            search_placeholder: '请输入片名或讲员名',
                            search_history: '搜索历史',
                            search_clear: '清除历史',
                            search_words: '搜索热词',
                            login: '登录/注册',
                            my_play: '播放记录',
                            my_favorite: '我的收藏',
                            my_note: '我的笔记',
                            notices: '站内公告',
                            about: '关于我们',
                            suggest: '我的反馈',
                            quit: '退出登录',
                            play_records: '观看记录',
                            all_records: '查看全部',
                        },
                        movieText: {
                            notices: '站内公告',
                            more: '更多',
                            author: '讲员',
                            new_add: '最新发布',
                            new_update: '最近更新',
                            hot_recommend: '热门推荐',
                            hot_rank: '热播排行',
                            software: '福音TV客户端下载',
                            software_intro: '提升软件 稳定性、增加会员、文章阅读、今日灵修、书卷查经等功能',
                            software_join: '如果您是客户端方向的技术人才，愿意参加服侍，那就 联系我们 吧！',
                            software_android: '安卓',
                            software_ios: '苹果',
                            empty: '没有相关内容',
                            hot_intro: '热播排行的100个视频',
                            add_intro: '最新发布的100个视频',
                            update_intro: '最近更新的100个视频',
                            filter_view: '排版',
                            filter_view_card: '图文',
                            filter_view_text: '文字',
                            filter_sort: '排序',
                            filter_sort_default: '默认',
                            filter_sort_add: '最新发布',
                            filter_sort_update: '最近更新',
                            filter_sort_hot: '热播排行',
                            filter_sort_recommend: '热门推荐',
                            filter_category: '类型',
                            recommend_intro: '专题介绍',
                            recommend_videos: '专题相关视频',
                            album_category: '分类',
                            album_author: '讲员',
                            album_intro: '简介',
                            album_more: '查看更多',
                            album_play: '播放',
                            album_favorite: '收藏',
                            album_playlist: '选集',
                            album_sort_asc: '正序排列 ↓',
                            album_sort_desc: '倒序排列 ↑',
                            album_list_video: '播放',
                            album_list_audio: '音频',
                            album_list_video_down: '视频下载',
                            album_list_audio_down: '音频下载',
                            album_list_doc_down: '文档下载',
                            album_recommend: '相关推荐',
                            album_hot: '热播排行',
                            album_software: '下载客户端',
                            album_software_intro: '使用便捷 观看稳定',
                            album_software_download: '立即下载',
                            album_notfound: '没有该资源',
                            player_current: '正在播放',
                            player_list: '选集',
                            player_favorite: '收藏',
                            player_note: '笔记',
                            player_suggest: '反馈',
                            player_download: '下载',
                            player_phone: '手机端观看',
                            player_play_list: '连续播放',
                            player_play_one_loop: '单曲循环',
                            player_recommend: '相关推荐',
                            player_intro: '影片介绍',
                            player_category: '分类',
                            player_source: '来源',
                            player_language: '语言',
                            player_author: '讲员',
                            author_label: '讲员库',
                            author_recommend: '推荐视频',
                            author_intro: '讲员简介',
                            author_videos: '讲员视频',
                            search: '搜索',
                            search_album: '专辑',
                            search_video: '剧集',
                            search_author: '讲员',
                            search_loading: '正在加载中，请稍后...',
                            search_notfound: '没有找到您要的内容，请更换关键词搜索或观看推荐视频',
                            search_recommend: '推荐内容',
                            search_more: '查看详情',
                            search_album_author: '讲员',
                            search_album_intro: '简介',
                            search_album_found: '检索到剧集',
                            search_album_detail: '查看专辑',
                            search_album_play: '播放剧集',
                            search_list_no: '序号',
                            search_list_title: '标题',
                            search_list_album: '所属专辑',
                            search_list_author: '讲员',
                            search_author_detail: '查看全部专辑',
                        },
                        mineText: {
                            menu: {
                                my_play: '播放记录',
                                my_favorite: '我的收藏',
                                my_note: '我的笔记',
                                notices: '站内公告',
                                about: '关于我们',
                                suggest: '我的反馈',
                            },
                            contentPlaceHolder: '我们的成长离不开您的支持和建议',
                            contentError: '请输入内容',
                            contactPlaceHolder: '请输入您的联系方式: QQ/邮箱/手机号',
                            contactError: '请输入正确的联系方式',
                            submit: '提 交',
                            successSubmit: '提交成功',
                            errorSubmit: '提交失败',
                            noteSearchPlaceholder: '搜索全部笔记',
                            notePlaceholder: '请在这里输入笔记内容',
                            noteError: '请输入笔记内容',
                            save: '保存',
                            play: '点击播放',
                            clear: '一键清空',
                            delete: '删除',
                            empty: '没有相关内容',
                            successSave: '保存成功',
                            successDelete: '删除成功',
                            errorSave: '保存失败',
                            errorClear: '请求失败',
                            errorDelete: '删除失败',
                        },
                        live: {
                            channel: '频道',
                            live_wait: '待直播',
                            live_play: '直播中',
                            live_end: '已结束',
                            live_replay: '回放',
                            live_start: '开播',
                            live_viewCount: '观看',
                            online: '在线',
                            download: '下载',
                            good: '点赞',
                            sponsor: '参与',
                            time: '时间',
                            content: '节目',
                            empty: '暂没有频道开播，请稍后',
                            liveIsStart: '直播将于{0}开始',
                            liveIsEnd: '直播已结束',
                            clickToReplay: '点击这里观看回放',
                        },
                        article: {
                            channel: '阅读号',
                            today: '今天',
                            latest7: '最近7天',
                            download: '更多下载APP观看',
                            read: '阅读',
                            good: '点赞',
                            comments: '精选留言',
                            addComment: '写留言',
                            guest: '游客',
                            top: '置顶',
                            noComments: '暂时没有留言',
                            notfound: '找不到该内容',
                            comment_placeholder: '请输入留言内容',
                            comment_error: '请输入留言内容',
                            comment_success: '留言成功',
                            comment_submit: '提 交'
                        },
                        bible: {
                            bible: '圣经',
                            new: '新约',
                            old: '旧约',
                            home_title: '福音TV-轻松读经',
                            home_continue: '继续阅读',
                            home_audio: '音频聆听',
                            home_audio_intro: '我们提供最经典的和合本与新译本，更多版本的聆听邀请您下载福音TV app。',
                            home_audio_do: '开始聆听',
                            home_text: '经文收藏',
                            home_text_intro: '您可以喜欢的经文收藏到您的收藏夹，方便以后再次阅读，或分享。',
                            home_text_do: '我的收藏',
                            home_app: 'APP下载',
                            home_app_intro: '下载福音TV APP，更多的读经功能、等你来体验。',
                            home_app_do: '立即下载',
                            home_bible: '圣经都是 神所默示的（或作“凡 神所默示的圣经”），于教训、督责、使人归正、教导人学义都是有益的，叫属 神的人得以完全，预备行各样的善事。',
                            home_bible_from: '/提摩太后书3:16-17/',
                            app: '福音TV',
                            app_download: 'APP下载',
                            chapter: '章',
                            prev: '上一章',
                            next: '下一章',
                            playAudio: '聆听',
                            book: '解经书',
                            book_notfound: '该解经书暂无相关内容，请更换其他解经书查看。',
                            fontsize: '字号',
                            theme: '背景',
                            version_placeholder: '请选择圣经版本',
                            version_multi: '多版本对照',
                            search: '搜索',
                            search_placeholder: '请输入关键词',
                            search_empty: '没有找到您要的内容，请更换关键词搜索',
                            search_result: '搜索结果',
                            search_result_text: '在《{0}》中找到了{1}条结果',
                            search_target: {
                                '': '整本圣经',
                                new: '新约',
                                old: '旧约',
                                moxi: '摩西五经',
                                history: '历史书',
                                poem: '诗歌、智慧书',
                                big: '大先知书',
                                small: '小先知书',
                                four: '四福音',
                                church: '教会历史',
                                paul: '保罗书信',
                                other: '其他书信',
                                revelation: '启示录',
                            },
                        },
                        login: {
                            back: '返回',
                            register: '注册账号',
                            forget: '忘记密码',
                            phone_placeholder: '请输入手机号',
                            email_placeholder: '请输入邮箱',
                            password_placeholder: '请输入密码',
                            smscode_placeholder: '请输入验证码',
                            smscode_send: '发送验证码',
                            do_login: '登 录',
                            do_register: '注 册',
                            do_reset_pwd: '重置密码',
                            login_qrcode: '扫码登录',
                            login_email: '邮箱登录',
                            login_sms: '短信登录',
                            qrcode_tip: '请使用 福音TV app扫描二维码登录',
                            agree: '同意',
                            agree_user: '《用户协议》',
                            agree_private: '《隐私政策》',
                            account_error: '请输入正确的手机号或邮箱',
                            email_error: '请输入正确的邮箱',
                            phone_error: '请输入正确的手机号',
                            login_error: '登录失败，请检查填写的内容',
                            register_success: '注册成功',
                            register_error: '注册失败，请检查填写内容是否正确',
                            reset_success: '密码重置成功，请登录',
                            reset_error: '密码重置失败，请检查填写内容是否正确',
                            imgcode_tip: '请先输入以下图片中的内容：',
                            imgcode_placeholder: '请输入内容',
                            imgcode_reset: '看不清？',
                            imgcode_submit: '提 交',
                        },
                        common: {
                            first_page: '首页',
                            prev_page: '上一页',
                            next_page: '下一页',
                            last_page: '尾页',
                            p404: '您访问的页面犹如迷失的小羊尚未找回！！！',
                            audio_not_found: '当前没有可播放的音频',
                            audio_playlist: '播放列表',
                            audio_versions: '音频版本',
                        }
                    },
                    'en-US': {
                        topMenu: {
                            movie: 'Movie',
                            live: 'Live',
                            bible: 'Bible',
                            article: 'Read',
                            dark: 'Dark',
                            light: 'Light'
                        },
                        movieMenu: {
                            home: 'Home',
                            hot: 'Hot list',
                            new_add: 'Latest Release',
                            new_update: 'Recent update',
                            sunday: 'Sermons',
                            all: 'All videos',
                            category: 'Category',
                            special_topic: 'Special Topic'
                        },
                        movieTop: {
                            search: 'Search',
                            search_placeholder: 'Search film title or author name...',
                            search_history: 'History',
                            search_clear: 'Clear',
                            search_words: 'Popular keywords',
                            login: 'Sign In',
                            my_play: 'History',
                            my_favorite: 'Favorites',
                            my_note: 'Notes',
                            notices: 'Notices',
                            about: 'About Us',
                            suggest: 'Suggest',
                            quit: 'Sign Out',
                            play_records: 'Watch History',
                            all_records: 'View all',
                        },
                        movieText: {
                            notices: 'Notices',
                            more: 'More',
                            author: 'Author',
                            new_add: 'Latest Release',
                            new_update: 'Recently Update',
                            hot_recommend: 'Recommend',
                            hot_rank: 'Hot list',
                            software: 'Download FuyinTV clients',
                            software_intro: 'New Features - Improve software stability, member center, reading, daily devotion, bible study, etc.',
                            software_join: 'If you are good at client-side technology and be willing to serve for god, please contact us!',
                            software_android: 'Android',
                            software_ios: 'iOS',
                            empty: 'Not found',
                            hot_intro: 'Top 100 Hot List',
                            add_intro: 'Top 100 Latest Release',
                            update_intro: 'Top 100 Recently Update',
                            filter_view: 'Display',
                            filter_view_card: 'Icons',
                            filter_view_text: 'Titles',
                            filter_sort: 'Sort by',
                            filter_sort_default: 'Default',
                            filter_sort_add: 'Latest Release',
                            filter_sort_update: 'Recently Update',
                            filter_sort_hot: 'Hot list',
                            filter_sort_recommend: 'Recommendation',
                            filter_category: 'Category',
                            recommend_intro: 'Introduction',
                            recommend_videos: 'Related recommendation',
                            album_category: 'Category',
                            album_author: 'Author',
                            album_intro: 'Introduction',
                            album_more: 'More',
                            album_play: 'Play',
                            album_favorite: 'Favorite',
                            album_playlist: 'Playlist',
                            album_sort_asc: 'Ascending ↓',
                            album_sort_desc: 'Descending ↑',
                            album_list_video: 'Play',
                            album_list_audio: 'Audio',
                            album_list_video_down: 'Video DL.',
                            album_list_audio_down: 'Audio DL.',
                            album_list_doc_down: 'Doc DL.',
                            album_recommend: 'Related Recommendation',
                            album_hot: 'Hot list',
                            album_software: 'Download Client',
                            album_software_intro: 'Convenient and Stable',
                            album_software_download: 'Download',
                            album_notfound: 'Not found',
                            player_current: 'Playing Now',
                            player_list: 'Playlist',
                            player_favorite: 'Favorite',
                            player_note: 'Notes',
                            player_suggest: 'Feedback',
                            player_download: 'Download',
                            player_phone: 'Mobile APP',
                            player_play_list: 'List loop',
                            player_play_one_loop: 'Single cycle',
                            player_recommend: 'Related Recommendation',
                            player_intro: 'Introduction',
                            player_category: 'Category',
                            player_source: 'From',
                            player_language: 'Language',
                            player_author: 'Author',
                            author_label: 'Author list',
                            author_recommend: 'Recommendation',
                            author_intro: 'About author',
                            author_videos: 'Author\'s videos',
                            search: 'Search',
                            search_album: 'Album',
                            search_video: 'Videos',
                            search_author: 'Author',
                            search_loading: 'Loading...',
                            search_notfound: 'We can\'t find the content, please change the keyword or watch other videos.',
                            search_recommend: 'Recommendation',
                            search_more: 'Details',
                            search_album_author: 'Author',
                            search_album_intro: 'Introduction',
                            search_album_found: 'Search Result',
                            search_album_detail: 'View album',
                            search_album_play: 'Play',
                            search_list_no: 'No.',
                            search_list_title: 'Title',
                            search_list_album: 'Album',
                            search_list_author: 'Author',
                            search_author_detail: 'View all',
                        },
                        mineText: {
                            menu: {
                                my_play: 'History',
                                my_favorite: 'Favorites',
                                my_note: 'Notes',
                                notices: 'Notices',
                                about: 'About Us',
                                suggest: 'Suggest',
                            },
                            contentPlaceHolder: 'Our progress is inseparable from your support and advice',
                            contentError: 'Leave your words here',
                            contactPlaceHolder: 'Your contact information: QQ/E-mail/Phone number',
                            contactError: 'Your contact information is incorrect, please check.',
                            submit: 'Submit',
                            successSubmit: 'Submit successfully',
                            errorSubmit: 'Submit failed',
                            noteSearchPlaceholder: 'Search all notes',
                            notePlaceholder: 'Enter your notes content here',
                            noteError: 'Note can not be empty',
                            save: 'Save',
                            play: 'Play',
                            clear: 'Clear',
                            delete: 'Delete',
                            empty: 'Not found',
                            successSave: 'Save successfully',
                            successDelete: 'Delete successfully',
                            errorSave: 'Save failed',
                            errorClear: 'Request failed',
                            errorDelete: 'Delete failed',
                        },
                        live: {
                            channel: 'Live',
                            live_wait: 'Awaiting',
                            live_play: 'Live',
                            live_end: 'End',
                            live_replay: 'Replay',
                            live_start: 'Start at',
                            live_viewCount: 'Views',
                            online: 'Online',
                            download: 'Download',
                            good: 'Like',
                            sponsor: 'Donate',
                            time: 'Time',
                            content: 'Live Stream',
                            empty: 'No live stream is on, please come back later',
                            liveIsStart: 'Live stream will start at {0} later',
                            liveIsEnd: 'Live stream is over.',
                            clickToReplay: 'Watch the replay',
                        },
                        article: {
                            channel: 'Read',
                            today: 'Today',
                            latest7: 'Last 7 days',
                            download: 'Download APP',
                            read: 'Read',
                            good: 'Like',
                            comments: 'Top Comments',
                            addComment: 'Leave Comment',
                            guest: 'Guest',
                            top: 'Pin',
                            noComments: 'No comment yet',
                            notfound: 'Not found',
                            comment_placeholder: 'Please enter ...',
                            comment_error: 'Comment can not be empty',
                            comment_success: 'Submit successfully',
                            comment_submit: 'Submit'
                        },
                        bible: {
                            bible: 'Bible',
                            new: 'NT',
                            old: 'OT',
                            home_title: 'FuyinTV - Easier Bible Reading',
                            home_continue: 'Continue',
                            home_audio: 'Audio Listening',
                            home_audio_intro: 'We offer a variety of versions of the Bible, and we invite you to download the APP',
                            home_audio_do: 'Start listening',
                            home_text: 'Scripture Collection',
                            home_text_intro: 'You can add your favorite scriptures to your favorites for later reading or sharing',
                            home_text_do: 'My Favorites',
                            home_app: 'Download APP',
                            home_app_intro: 'Download FuyinTV APP, more features are waiting for you',
                            home_app_do: 'Download Now',
                            home_bible: 'All Scripture is God-breathed and is useful for teaching, rebuking, correcting and training in righteousness, so that the man of God may be thoroughly equipped for every good work.',
                            home_bible_from: '/2 Tim 3:16-17/',
                            app: 'FuyinTV',
                            app_download: 'Download',
                            chapter: 'Chapter',
                            prev: 'Prev',
                            next: 'Next',
                            playAudio: 'Audio',
                            book: 'Explanations',
                            book_notfound: 'Not found',
                            fontsize: 'Text size',
                            theme: 'Theme',
                            version_placeholder: 'Please select your Bible version',
                            version_multi: 'Multi-version comparison',
                            search: 'Search',
                            search_placeholder: 'Search...',
                            search_empty: 'Not found',
                            search_result: 'Search Result',
                            search_result_text: '{1} results from 《{0}》',
                            search_target: {
                                '': 'Entire Bible',
                                new: 'New Testament',
                                old: 'Old Testament',
                                moxi: 'Pentateuch',
                                history: 'History',
                                poem: 'Poetry',
                                big: 'Major Prophets',
                                small: 'Minor Prophets',
                                four: 'Gospels',
                                church: 'History',
                                paul: 'Pauline letters',
                                other: 'General letters',
                                revelation: 'Prophecy',
                            },
                        },
                        login: {
                            back: 'Back',
                            register: 'Sign Up',
                            forget: 'Forgot password',
                            phone_placeholder: 'Phone Number',
                            email_placeholder: 'E-mail',
                            password_placeholder: 'Password',
                            smscode_placeholder: 'Auth code',
                            smscode_send: 'Send',
                            do_login: 'Sign In',
                            do_register: 'Sign Up',
                            do_reset_pwd: 'Reset password',
                            login_qrcode: 'QR code',
                            login_email: 'E-mail',
                            login_sms: 'SMS',
                            qrcode_tip: 'Please open FuyinTV APP and scan the QR code to sign in',
                            agree: 'I agree',
                            agree_user: '《User Agreement》',
                            agree_private: '《Privacy Policy》',
                            account_error: 'Phone number or E-mail address incorrect',
                            email_error: 'E-mail address incorrect',
                            phone_error: 'Phone number incorrect',
                            login_error: 'Sign In failed, please check your information',
                            register_success: 'Registered successfully',
                            register_error: 'Registered failed, please check your information',
                            reset_success: 'Reset password successfully, please sign in again',
                            reset_error: 'Reset password failed, please check your information',
                            imgcode_tip: 'Please enter the numbers shown in the image below:',
                            imgcode_placeholder: 'Enter the numbers',
                            imgcode_reset: 'Can’t see？',
                            imgcode_submit: 'Submit',
                        },
                        common: {
                            first_page: 'First',
                            prev_page: 'Prev',
                            next_page: 'Next',
                            last_page: 'Last',
                            p404: 'The page you visit is like a lost lamb that has not been found!!!',
                            audio_not_found: 'No audio available',
                            audio_playlist: 'Playlist',
                            audio_versions: 'Audio version',
                        },
                    }
                }
            },
        }
    }

    format(template: string, ...args: any[]) {
        for (let i = 0; i < args.length; i++) {
            template = template.replace('{' + i + '}', args[i].toString())
        }
        return template
    }
}

export default new I18()
