import { LOAD_BIBLE_SEARCH } from '@/store/protocols'
export default [
    {
        name: 'bible-search',
        path: '/bibles-search/:search/:lang/:type/:page',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_BIBLE_SEARCH.join('/')
        }
    }
]