import api from '@/api/articleApi';
import BaseModule from "@/common/BaseModule";
import { Module, Store } from "vuex";
import { LOAD_ARTICLES_HOME, SEO } from '../protocols';
export interface State {
    categories: ArticleCategory[],
    category: string,
    feeds: ArticleFeed[],
    feedIndex: number,
    feedsVisible: boolean,
    article: Article | null,
    comments: any[],
}
class ArticleHome extends BaseModule<State>{
    constructor() {
        super(LOAD_ARTICLES_HOME[0])
    }
    generate(): Module<State, any> {
        return {
            namespaced: true,
            state: {
                categories: [],
                category: '',
                feeds: [],
                feedIndex: -1,
                feedsVisible: true,
                article: null,
                comments: [],
            },
            mutations: {
                updateArticle(state, data) {
                    state.article = data
                }
            },
            actions: {
                async loadCategories({ state }, route: Route) {
                    if (state.categories.length == 0) {
                        try {
                            state.categories = await api.getCategorys()
                        } catch (e) {
                        }
                    }
                },
                async loadFeeds({ state, dispatch }, key: string) {
                    if (state.category != key) {
                        try {
                            state.feeds = await api.getFeeds(1, 7, key)
                            state.category = key
                        } catch (e) {
                            state.feeds = []
                        } finally {
                            state.feedIndex = -1
                        }
                    }
                },
                async loadContent({ dispatch, commit, state }, route: Route) {
                    if (state.feeds.length == 0) {
                        await dispatch('loadFeeds', 'ttlx')
                    }
                    try {
                        let id = route.params.id || state.feeds[0].list[0].itemid
                        state.article = await api.getDetail(id)
                        let seoTitle = state.article.title + '-福音阅读-福音影视网|福音TV'
                        commit('updateSeo', Object.assign({}, SEO, { title: seoTitle }), { root: true })
                        state.comments = await api.getComments(id)
                    } catch (e) {
                     }
                },
                async postComment({ state }, data: { id: number, content: string }) {
                    await api.postComment(data.id, data.content)
                    state.comments.push({ nickname: '用户', content: data.content, addtime: Math.floor(Date.now() / 1000) })
                },
                [LOAD_ARTICLES_HOME[1]]({ dispatch, state, commit }, route: Route) {
                    return Promise.all([
                        dispatch('loadCategories', route),
                        dispatch('loadContent', route),
                        // dispatch(LOAD_SEO, { file: 'hot' }, { root: true })
                    ])
                }
            }
        }
    }

    setCategory(store: Store<any>, key: string) {
        return store.dispatch(this.key('loadFeeds'), key)
    }

    postComment(store: Store<any>, id: number, content: string) {
        return store.dispatch(this.key('postComment'), { id, content })
    }
}

export default new ArticleHome();