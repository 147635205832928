import api from '@/api/fyApi';
import BaseModule from "@/common/BaseModule";
import { Module } from "vuex";
import { LOAD_MINE_NEWS } from '../protocols';

export interface State {
    list: News[],
    page: number
}

class NewsModule extends BaseModule<State>{
    constructor() {
        super(LOAD_MINE_NEWS[0])
    }
    generate(): Module<State, any> {
        return {
            namespaced: true,
            state: {
                list: [],
                page: 1,
            },
            mutations: {
                update(state, { list, page }) {
                    state.list = list
                    state.page = page
                }
            },
            actions: {
                async [LOAD_MINE_NEWS[1]]({ commit }) {
                    let { current_page, data } = await api.getNews(1)
                    commit('update', { list: data, page: current_page })
                }
            }
        }
    }
}

export default new NewsModule()