export function n2(value: number) {
    return value < 10 ? '0' + value : value;
}

export function date(value: number) {
    if (isNaN(value)) {
        return '--'
    }
    let dt = new Date(value * 1000);
    return `${dt.getFullYear()}-${n2(dt.getMonth() + 1)}-${n2(dt.getDate())}`
}

export function datetime(value: number) {
    if (isNaN(value)) {
        return '--'
    }
    let dt = new Date(value * 1000);
    return `${dt.getFullYear()}-${n2(dt.getMonth() + 1)}-${n2(dt.getDate())} ${n2(dt.getHours())}:${n2(dt.getMinutes())}`
}

export function date2(value: number) {
    if (isNaN(value)) {
        return '--'
    }
    let dt = new Date(value * 1000);
    let now = new Date();
    if (dt.getFullYear() == now.getFullYear() && dt.getMonth() == now.getMonth() && dt.getDate() == now.getDate()) {
        return '今天'
    }

    return `${n2(dt.getMonth() + 1)}/${n2(dt.getDate())}`
}

export function leftTime(value: number) {
    if (isNaN(value)) {
        return '--'
    }
    value = Math.floor(value)
    let seconds = value % 60
    value = Math.floor(value / 60)
    let minutes = value % 60
    let hours = Math.floor(value / 60)
    let text = `${n2(minutes)}:${n2(seconds)}`
    if (hours > 0) {
        text = `${hours}:${text}`
    }
    return text;
}

export function unit(value: number) {
    if (value > 10000) {
        let suffix = ''
        value = value / 10000
        suffix = '万'
        if (value > 10000) {
            value = value / 10000
            suffix = '亿'
        }
        return value.toFixed(1) + suffix
    } else {
        return value
    }
}

export function bg(url?: string) {
    if (url) {
        return {
            backgroundImage: 'url(' + url + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat'
        }
    }
}