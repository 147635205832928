import { LOAD_MOVIE_AUTHORS_PAGE, LOAD_MOVIE_AUTHOR_PAGE } from '@/store/protocols'
export default [
    {
        path: 'authors',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_MOVIE_AUTHORS_PAGE.join('/')
        }
    },
    {
        name: 'movies/authors',
        path: 'authors/index.html',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_MOVIE_AUTHORS_PAGE.join('/')
        }
    },
    {
        path: 'authors/:id/index.html',
        component: () => import('./Detail.vue'),
        meta: {
            asyncData: LOAD_MOVIE_AUTHOR_PAGE.join('/')
        }
    },
    {
        name: 'movies/authors/detail',
        path: 'authors/:id/page/:page/index.html',
        component: () => import('./Detail.vue'),
        meta: {
            asyncData: LOAD_MOVIE_AUTHOR_PAGE.join('/')
        }
    }
]