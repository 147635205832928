import { HttpRequestOptions } from "@/common/http";
import { getLang } from '../config';
import BaseApi from "./BaseApi";
class ChajingApi extends BaseApi {
    constructor() {
        super('https://chajing-api.sanmanuela.net')
    }

    preRequest(options: HttpRequestOptions): HttpRequestOptions {
        options.query = Object.assign({}, options.query, { lang: getLang() })
        return options;
    }

    postRequest(response: any, api: string) {
        if (response.code == 0) {
            return response.data
        }
        throw new Error(response.msg)
    }

    getBooks(volume: number, chapter: number) {
        return this.get(`/api/chajing/index/special_list_by_vc`, { v: volume, c: chapter })
    }

    getBookDetail(volume: number, chapter: number, sid: number) {
        return this.get(`/api/chajing/index/get_data_by_svc`, { v: volume, c: chapter, sid })
    }

}

export default new ChajingApi()
