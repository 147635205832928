import fyApi from '@/api/fyApi';
import BaseModule from "@/common/BaseModule";
import { Module } from "vuex";
import { LOAD_MOVIE_TOPIC_PAGE, LOAD_SEO } from '../protocols';

export interface State {
    topic?: MovieTopicDetail,
    albums: MovieAlbum[],
    topicId: ID,
}

class MoviesTopic extends BaseModule<State>{
    constructor() {
        super(LOAD_MOVIE_TOPIC_PAGE[0])
    }
    generate(): Module<State, any> {
        return {
            namespaced: true,
            state: {
                albums: [],
                topicId: 0,
            },
            mutations: {
                updateTopic(state, data) {
                    state.topic = data
                },
                updateAlbums(state, data) {
                    state.albums = data
                },
                updateTopicId(state, value) {
                    state.topicId = value
                }
            },
            actions: {
                async loadTopic({ commit }, { params }: Route) {
                    commit('updateTopicId', params.id)
                    let result = await fyApi.getMovieTopic(params.id)
                    commit('updateTopic', result)
                    fyApi.postHit('special', params.id)
                },
                async loadMovieAlbums({ commit }, { params }: Route) {
                    let { data } = await fyApi.getMovieTopicAlbums(params.id)
                    commit('updateAlbums', data)
                },
                [LOAD_MOVIE_TOPIC_PAGE[1]]({ dispatch }, route: Route) {
                    return Promise.all([
                        dispatch('loadTopic', route),
                        dispatch('loadMovieAlbums', route),
                        dispatch(LOAD_SEO, { file: 'special', query: route.params }, { root: true })
                    ])
                }
            }
        }
    }
}

export default new MoviesTopic()