import api from '@/api/bibleApi';
import { isSSR } from '../../config';
import chajing from '@/api/chajingApi'
import fyApi from '@/api/fyApi';
import BaseModule from "@/common/BaseModule";
import bibleContents from '@/views/assets/contents';
import { Module } from "vuex";
import { LOAD_BIBLE_DETAIL, SEO } from '../protocols';
import { loadVersions, getVersionConfig, getVersionConfig2 } from './search';
import { Store } from 'vuex'
export interface State {
    volume: number,
    chapter: number
    lang: string,
    langLabel: string,
    contents: Array<{ i: number, vers: Array<{ ver: string, text: string }> }>,
    color: number,
    font: number,
    vers: string[],
    bookSid: number,
    bookTitle: string,
    bookContent: string,
    bookVisible: boolean,
    doubleMode: boolean,
}
class BibleDetail extends BaseModule<State> {
    constructor() {
        super(LOAD_BIBLE_DETAIL[0])
    }

    generate(): Module<State, any> {
        return {
            namespaced: true,
            state: {
                volume: 0,
                chapter: 0,
                lang: 'hhb',
                langLabel: '',
                contents: [],
                color: 1,
                font: 18,
                vers: [],
                bookSid: 0,
                bookTitle: '',
                bookContent: '',
                bookVisible: false,
                doubleMode: false,
            },
            mutations: {
                setColor(state, value) {
                    state.color = value
                    window.localStorage.setItem('bcolor', value + '')
                }
            },
            actions: {
                async loadContent({ state, commit, dispatch }, vers: string[]) {
                    await dispatch('bible-search/init', {}, { root: true })
                    await loadVersions()
                    vers = vers.map(ver => getVersionConfig2(ver))
                        .filter(item => item)
                        //@ts-ignore
                        .map(item => item.key)
                    if (vers.length == 0) {
                        vers = ['hhb']
                    }
                    state.vers = vers
                    let contents: Array<{ i: number, vers: Array<{ ver: string, text: string }> }> = []
                    let maxJ = 0
                    for (let i = 0; i < vers.length; i++) {
                        let lang = getVersionConfig(vers[i])
                        if (i == 0) {
                            state.lang = lang.key
                            state.langLabel = lang.label
                        }
                        let content = await api.getContent(state.volume, state.chapter, lang.key, lang.json)
                        for (let item of content) {
                            let si = item.indexOf('-')
                            let j0 = item.substring(0, si)
                            let text = item.substring(si + 1)
                            text = text.replace(/\{\{\[(.+?)\]\}\}/g, function (_, json) {
                                return ``
                            })
                            text = text.replace(/<m>/g, '(').replace(/<\/m>/g, ')')
                            let j = parseInt(j0)
                            if (i == 0 && j == 0) {
                                contents.push({ i: j, vers: [{ ver: '', text }] })
                                continue
                            }
                            while (maxJ < j) {
                                maxJ++
                                contents.push({ i: maxJ, vers: [] })
                            }
                            let target = contents.find(target => target.i == j)
                            if (target) {
                                target.vers.push({ ver: lang.label, text })
                            }
                        }
                    }
                    state.contents = contents
                    let contentItem = bibleContents[state.volume]
                    let seoTitle = `${contentItem.name}${state.chapter + 1}章-在线圣经-福音影视网|福音TV`
                    commit('updateSeo', Object.assign({}, SEO, { title: seoTitle }), { root: true })
                    if (state.bookSid > 0) {
                        dispatch('setBook', { sid: state.bookSid, title: state.bookTitle })
                    }
                },
                async setBook({ state }, { sid, title, visible }) {
                    let r = await chajing.getBookDetail(state.volume + 1, state.chapter + 1, sid)
                    state.bookSid = sid
                    state.bookTitle = title
                    if (r) {
                        state.bookContent = r.content.replace(/<audio.+?><\/audio>/g, '')
                    } else {
                        state.bookContent = ''
                    }
                    state.doubleMode = true
                },
                quitBook({ state }) {
                    state.bookSid = 0
                    state.bookTitle = ''
                    state.bookContent = ''
                    state.bookVisible = false
                    state.doubleMode = false
                },
                [LOAD_BIBLE_DETAIL[1]]({ dispatch, state, commit }, route: Route) {
                    state.volume = parseInt(route.params.volume) - 1
                    state.chapter = parseInt(route.params.chapter) - 1
                    if (state.volume >= 0 && state.chapter >= 0) {
                        if (state.vers.length > 0) {
                            return Promise.all([
                                dispatch('loadContent', state.vers),
                            ])
                        } else if (isSSR && ['qihoobot', 'Baiduspider', 'Googlebot'].some(key => fyApi.userAgent.indexOf(key) >= 0)) {
                            return Promise.all([
                                dispatch('loadContent', ['hhb']),
                            ])
                        }
                    } else {
                        throw new Error('404')
                    }
                }
            }
        }
    }

    setVersions(store: Store<any>, vers: string[]) {
        store.dispatch(this.key('loadContent'), vers);
        window.localStorage.setItem('bVers', vers.join(','))
    }

    setBook(store: Store<any>, sid: number, title: string) {
        store.dispatch(this.key('setBook'), { sid, title, visible: true })
    }

    quitBook(store: Store<any>) {
        store.dispatch(this.key('quitBook'))
    }
}

export default new BibleDetail()