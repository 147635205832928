const isDev = process.env.NODE_ENV == "development"
const isSSR = (function () {
    try {
        window.document
        return false
    } catch (e) {
        return true
    }
})()

export { isSSR, isDev }

export const version = '1.0.0'
export const app = 'fuyintv'
export const device = 'pc'
export const setting = {
    lang: 'zh-CN',
    first: true,
}

export function getLang() {
    switch (setting.lang) {
        case 'en-US': return 'en';
        default: return 'zh';
    }
}

export function parseSearch() {
    let query: any = {}
    if (window.location.search) {
        let pairs = window.location.search.substring(1).split('&').map(s => s.split('='))
        for (let ks of pairs) {
            if (ks[0]) {
                query[ks[0]] = ks[1]
            }
        }
    }
    return query
}

export function toSearch(data: any) {
    let arr: string[] = []
    for (let k in data) {
        arr.push(k + '=' + data[k])
    }
    if (arr.length > 0) {
        return '?' + arr.join('&')
    }
    return ''
}