import api from '@/api/bibleApi';
import BaseModule from "@/common/BaseModule";
import { Module } from "vuex";
import { LOAD_BIBLE_SEARCH, SEO } from '../protocols';
export interface State {
    search: string,
    lang: string,
    langLabel: string,
    type: number,
    page: number,
    size: number,
    total: number,
    target: number,
    result: Array<{
        "volume": number,
        "chapter": number,
        "section": number,
        "detail": string
    }>,
}
const versions: Array<{
    lang: string, versions: Array<{
        label: string, key: string, json: string,
    }>
}> = []

const volumes = [
    { key: '', volume: '' },
    { key: 'new', volume: '' },
    { key: 'old', volume: '' },
    { key: 'moxi', volume: '1,2,3,4,5' },
    { key: 'history', volume: '6,7,8,9,10,11,12,13,14,15,16,17' },
    { key: 'poem', volume: '18,19,20,21,22' },
    { key: 'big', volume: '23,24,25,26,27' },
    { key: 'small', volume: '28,29,30,31,32,33,34,35,36,37,38,39' },
    { key: 'four', volume: '40,41,42,43' },
    { key: 'church', volume: '44' },
    { key: 'paul', volume: '45,46,47,48,49,50,51,52,53,54,55,56,57' },
    { key: 'other', volume: '58,59,60,61,62,63,64,65' },
    { key: 'revelation', volume: '66' },
]

export function getVersionConfig(key: string) {
    for (let item of versions) {
        for (let item0 of item.versions) {
            if (item0.key == key) {
                return item0
            }
        }
    }
    return versions[0].versions[0]
}

export function getVersionConfig2(key: string) {
    for (let item of versions) {
        for (let item0 of item.versions) {
            if (item0.key == key) {
                return item0
            }
        }
    }
    return false
}

export async function loadVersions() {
    if (versions.length == 0) {
        try {
            let result = await api.getContents()
            versions.push(...result)
            for (let item of versions) {
                for (let item0 of item.versions) {
                    if (item0.key == 'hhb') {
                        item0.json = '/bible/${v}-${c}.json'
                    } else if (item0.key == 'xyb') {
                        item0.json = '/bible/${v}-${c}-xinyiben.json'
                    } else if (item0.key == 'niv') {
                        item0.json = '/bible/${v}-${c}-NIV.json'
                    } else {
                        item0.json = '/bible_json/${lang}/${v}-${c}.json'
                    }
                }
            }
        } catch (e) {
        }
    }
}

class BibleSearch extends BaseModule<State> {
    constructor() {
        super(LOAD_BIBLE_SEARCH[0])
    }

    generate(): Module<State, any> {
        return {
            namespaced: true,
            state: {
                search: '',
                lang: 'hhb',
                langLabel: '',
                type: 0,
                page: 0,
                size: 20,
                total: 0,
                target: -1,
                result: [],
            },
            getters: {
                types() {
                    return volumes
                },
                versions() {
                    return versions
                }
            },
            actions: {
                init() {
                    return loadVersions()
                },
                async loadContent({ state, commit }, { params }: Route) {
                    state.search = params.search
                    state.type = parseInt(params.type)
                    state.page = parseInt(params.page)

                    await loadVersions()
                    let lang = getVersionConfig(params.lang)
                    state.lang = lang.key
                    state.langLabel = lang.label

                    if (isNaN(state.type) || state.type < 0) {
                        state.type = 0
                    }
                    if (isNaN(state.page) || state.page < 0) {
                        state.page = 0
                    }
                    if (state.search) {
                        let query: any = {
                            detail: state.search,
                            page: state.page,
                            size: state.size
                        }
                        let type = volumes[state.type]
                        if (type && type.key) {
                            if (type.key == 'old' || type.key == 'news') {
                                query.part = type.key
                            } else {
                                query.volume = type.volume
                            }
                        }

                        query.lng = state.lang
                        let { total, list } = await api.search(query)
                        state.total = total
                        state.result = list
                    } else {
                        state.total = 0
                        state.result = []
                    }
                    let seoTitle = '在线圣经-福音影视网|福音TV'
                    commit('updateSeo', Object.assign({}, SEO, { title: seoTitle }), { root: true })
                },
                [LOAD_BIBLE_SEARCH[1]]({ dispatch, state, commit }, route: Route) {
                    return Promise.all([
                        dispatch('loadContent', route),
                    ])
                }
            }
        }
    }
}

export default new BibleSearch()