import BaseModule from "@/common/BaseModule";
import { Module } from "vuex";
import { LOAD_BIBLE_HOME, SEO } from '../protocols';
export interface State {
}
class BibleHome extends BaseModule<State>{
    constructor() {
        super(LOAD_BIBLE_HOME[0])
    }

    generate(): Module<State, any> {
        return {
            namespaced: true,
            state: {
            },
            actions: {
                async loadContent({ commit }) {
                    let seoTitle = '在线圣经-福音影视网|福音TV'
                    commit('updateSeo', Object.assign({}, SEO, { title: seoTitle }), { root: true })
                },
                [LOAD_BIBLE_HOME[1]]({ dispatch, state, commit }, route: Route) {
                    return Promise.all([
                        dispatch('loadContent', route),
                    ])
                }
            }
        }
    }
}

export default new BibleHome()