import api from '@/api/userApi';
import BaseModule from "@/common/BaseModule";
import { Module, Store } from "vuex";
import { LOAD_MINE_FAVORITES, LOAD_MOVIE_ALBUM_PAGE, LOAD_MOVIE_PLAYER_PAGE } from '../protocols';
import { wrap401 } from '../user';
export interface State {
    list: Favorite[],
    page: number
}

class Favorites extends BaseModule<State> {
    constructor() {
        super(LOAD_MINE_FAVORITES[0])
    }
    generate(): Module<State, any> {
        return {
            namespaced: true,
            state: {
                list: [],
                page: 1
            },
            mutations: {
                update(state, { list, page }) {
                    state.list = list;
                    state.page = page
                },
                clear(state) {
                    state.list = []
                }
            },
            actions: {
                [LOAD_MINE_FAVORITES[1]](store) {
                    return wrap401(store, async () => {
                        let { data, current_page } = await api.getFavorites(1, 9999)
                        store.commit('update', { list: data, page: current_page })
                        store.dispatch(LOAD_MOVIE_ALBUM_PAGE[0] + '/refreshFavorite', {}, { root: true })
                        store.dispatch(LOAD_MOVIE_PLAYER_PAGE[0] + '/refreshFavorite', {}, { root: true })
                    })
                },
                remove(store, i: number) {
                    return wrap401(store, async () => {
                        let item = store.state.list[i]
                        await api.removeFavorite({ movid: item.movid, id: item.id })
                        store.state.list.splice(i, 1)
                    })
                },
                clear(store) {
                    return wrap401(store, async () => {
                        await api.clearFavorites()
                        store.commit('update', { list: [], page: 1 })
                    })
                },
                async addId({ state }, id: number) {
                    await api.addFavorite(id)
                    state.list.push({
                        movid: id, id: 0, create_time: 0, movie: {
                            movid: id,
                            title: '',
                            speaker: '',
                            thumb: '',
                            addtime: 0,
                            edittime: 0
                        }
                    })
                },
                async removeId({ state }, id: number) {
                    await api.removeFavorite({ movid: id })
                    let i = state.list.findIndex(item => item.movid == id)
                    if (i >= 0) {
                        state.list.splice(i, 1)
                    }
                }
            }
        }
    }

    loadList(store: Store<any>) {
        store.dispatch(this.key(LOAD_MINE_FAVORITES[1]))
    }

    addId(store: Store<any>, id: number) {
        return store.dispatch(this.key('addId'), id)
    }

    removeId(store: Store<any>, id: number) {
        return store.dispatch(this.key('removeId'), id)
    }

    remove(store: Store<any>, index: number) {
        return store.dispatch(this.key('remove'), index)
    }

    clear(store: Store<any>) {
        return store.dispatch(this.key('clear'))
    }
}

export default new Favorites()