import { LOAD_BIBLE_DETAIL } from '@/store/protocols'
export default [
    {
        name: 'bible-detail',
        path: '/bibles/:lang/:volume/:chapter.html',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_BIBLE_DETAIL.join('/')
        }
    }
]