import { LOAD_MOVIE_LATEST_PAGE, LOAD_MOVIE_PUBLISH_PAGE } from '@/store/protocols'
export default [
    {
        path: '/content/update',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_MOVIE_LATEST_PAGE.join('/')
        }
    },
    {
        name: 'movies/latest',
        path: '/content/update/index.html',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_MOVIE_LATEST_PAGE.join('/')
        }
    },
    {
        path: '/content/publish',
        component: () => import('./Publish.vue'),
        meta: {
            asyncData: LOAD_MOVIE_PUBLISH_PAGE.join('/')
        }
    },
    {
        path: '/content/publish/index.html',
        component: () => import('./Publish.vue'),
        meta: {
            asyncData: LOAD_MOVIE_PUBLISH_PAGE.join('/')
        }
    }
]