import { LOAD_MOVIE_TOPIC_PAGE } from '@/store/protocols'
export default [
    {
        path: 'topics/:id',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_MOVIE_TOPIC_PAGE.join('/')
        }
    },
    {
        name: 'movies/topics',
        path: 'topics/:id/index.html',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_MOVIE_TOPIC_PAGE.join('/')
        }
    }
]