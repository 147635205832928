import { LOAD_HOME_PAGE } from '@/store/protocols'

export default [
    {
        path: '/',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_HOME_PAGE.join('/')
        }
    },
    {
        name: 'home',
        path: '/index.html',
        component: () => import('./Root.vue'),
        meta: {
            asyncData: LOAD_HOME_PAGE.join('/')
        }
    }
]