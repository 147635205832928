export interface RootState {
    moviesConfig: {
        menus: Array<{ name: string, icon: string, action: string }>,
        categories: MovieCategory[],
        topics: MovieTopic[],
        links: Array<{ title: string, linkurl: string }>,
        footer_text: string,
        footer_logo: string,
        hotsearch: string[],
        ucenter: boolean
    },
    phoneAreas: PhoneArea[],
    dialogs: {
        count: number,
        login: any,
        loginQr: any,
        comments: any,
        bibleSetting: any,
        imgCode: any,
    },
    seo: {
        title: string,
        keywords: string,
        description: string,
    },
    videoFullScreen: boolean,
    page404: boolean,
    darkMode: boolean,
}

export const SET_404 = 'updateP404'
export const LOAD_COMMON = 'load-common';
export const LOAD_SEO = 'load-seo';
export const LOAD_HOME_PAGE = ['movie-home', 'load-home-page'];
export const LOAD_MOVIE_CATEGORIES_PAGE = ['movie-categories', 'load-movie-categories-page']
export const LOAD_MOVIE_ALBUM_PAGE = ['movie-album', 'load-movie-album-page']
export const LOAD_MOVIE_AUTHORS_PAGE = ['movie-authors', 'load-movie-authors-page']
export const LOAD_MOVIE_AUTHOR_PAGE = ['movie-author', 'load-movie-author-page']
export const LOAD_MOVIE_HOT_PAGE = ['movie-hot', 'load-movie-hot-page']
export const LOAD_MOVIE_LATEST_PAGE = ['movie-latest', 'load-movie-latest-page']
export const LOAD_MOVIE_PUBLISH_PAGE = ['movie-publish', 'load-movie-publish-page']
export const LOAD_MOVIE_ALL_PAGE = ['movie-all', 'load-movie-all-page']
export const LOAD_MOVIE_SEARCH_PAGE = ['movie-search', 'load-movie-search-page']
export const LOAD_MOVIE_TOPIC_PAGE = ['movies-topic', 'load-movie-topic-page']
export const LOAD_MOVIE_PLAYER_PAGE = ['movie-player', 'load-movie-player-page']

export const LOAD_LIVE_HOME = ['live-home', 'load-home-page']
export const LOAD_ARTICLES_HOME = ['articles-home', 'load-articles-home']

export const LOAD_BIBLE_HOME = ['bible-home', 'load-bible-home']
export const LOAD_BIBLE_DETAIL = ['bible-detail', 'load-bible-page']
export const LOAD_BIBLE_SEARCH = ['bible-search', 'load-search-page']

export const LOAD_MINE_NEWS = ['mine-news', 'load-mine-news-page']
export const LOAD_MINE_FAVORITES = ['mine-favorites', 'load-mine-favorites-page']


export const SEO = {
    description: "这是一个正规基督教网站，包含基督教信息、基督教视频、牧师讲道、福音电影、基督教动漫、基督教诗歌、等所有相关福音信息，本站是以提供正统基督教视频为主的网站，旨在为了更好的造就信徒服侍教会，促使教会和信徒在神的话语根基上更加稳固健康，抵制异端，正本清源。",
    keywords: "新网址ifuyin.net,基督教网站,福音网站,基督教牧师讲道视频,福音视频,基督教影视,基督教视频,天国福音,基督教网站,基督教门户网,基督网站,耶稣视频-福音影视网",
    title: "福音影视网|福音TV|基督教影视网-基督教视频网站",
}
