import about from './about'
import favorites from './favorites'
import news from './news'
import notes from './notes'
import playbacks from './playbacks'
import profile from './profile'
import Root from './Root.vue'
import suggest from './suggest'

export default [
    {
        path: '/mine',
        component: Root,
        children: [
            profile,
            playbacks,
            notes,
            favorites,
            news,
            about,
            suggest
        ]
    }
]